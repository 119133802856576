.main-content .document-downloads, .document-downloads,
.html-block .document-downloads {
    &__link {
        background: $c-lightgreycta-bg url($imgPath + "list-document.png") no-repeat 20px 15px;
        width: 100%;
        display: block;
        font-size: 18px;
        text-decoration: none;
        color: $c-document-download-link;
        font-family: "Centennial Roman", serif;
        padding: 17px 30px 27px 85px;
        margin-bottom: 20px;
        box-shadow: none;
        @media (min-width: $screen-sm-min) {
            padding: 27px 60px 27px 114px;
            background-position: 40px 50%;
        }
    }

    &__view {
        color: $c-document-download-link;
        box-shadow: 0px 3px 0px -2px $c-lightblue;
        display: inline-block;
        font-size: 16px;
        &:after {
            display: inline-block;
            background: url($imgPath + "pagination-next.png") no-repeat 0 0;
            width: 21px;
            height: 9px;
            content: "";
            margin-left: 5px;
        }

        @media (min-width: $screen-sm-min) {
            float: right;
            display: inline;
        }
    }
}

.document-key {
    display: block;
    margin-bottom: 5px;
}