.footer {
    &-top {
        background-color: $footer-top-background-color;
        color: white;
        padding: 22px 0;

        .footer-logo img {
            max-width: 100%;
            height: auto;
        }
    }

    &-bottom {
        background-color: white;
        color: $c-hardgrey;
        font-size: 16px;
        padding: 22px 0;

        .tertiary {
            @media (min-width: $screen-sm-min) {
                float: right;
            }

            &__link {
                color: $c-hardgrey;
              
            }

            &__item {
                &:not(:last-child):after {
                    content: "|";
                    display: inline-block;
                    color: $c-hardgrey;
                    margin-right: 11px;
                    padding-left: 11px;
                }
            }
        }

    }

    &-links {
        padding-left: 0;
        list-style: none;
        display: none;

        @media (min-width: $screen-sm-min) {
            display: block;
        }

        &__heading {
            margin-bottom: 5px;
            > a {
                color: white;
                font-size: 16px;
                font-weight: bold;
                line-height: 1.2;
                display: block;
                margin-bottom: 10px;
            }
        }

        &__item {
            margin-bottom: 10px;
        }
        &__link {
            color: white;
            font-size:14px;
        }
    }
}

//ie11 Hack to allow for even spacing of twitter icon
_:-ms-lang(x), 
a.social-icons.social-icons--twitter {
        font-size: 7px;
}
