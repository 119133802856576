.modal {
    &-backdrop.in {
        opacity: 0.85;
    }
    &-dialog {
        @media (min-width: $screen-sm-min) {
            margin-top: 100px;
        }
    }

    &.is-active { display: block; }

    &__inner {
        background: white;
        position: relative;
        padding: 30px;
    }

    &__actions {
        text-align: center;
        display: block;
        margin: 25px auto 0;

        @media (max-width: $screen-sm-max) {
            .accept {
                margin-bottom: 10px;
            }
        }
    }

    &--active {
        &:before {
            background-color: rgba(0,0,0,0.87);
            content: "";
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 100;
        }
    }
}

.information-modal {
  &[data-override-default] {
    display: none;
  }
}
