//
// Base -> Variables
//
// Use this file to customise Bootstrap variables
// ahead of compilation.
// (See vendor/bootstrap/_variables.scss)
// --------------------------------------------------

$imgPath: '/custom/build/img/';
// Colour Palette Colours

$c-lightblue: #008099;
$c-blog-light-grey: #465863;
$c-springsky: #ccd8e5;
$c-darkblue: #005596;
$c-medgrey: #676c73;
$c-lightgrey: #f3f3f1;
$c-darkgrey: #2e2b27;
$c-orange: #d6753d;
$c-green: #a3b497;
$c-paleblue: #f3f3f1;
$c-yellow: #e9ba66;
$c-royalblue: #00234c;
$c-red: red;
$c-disclaimer-bg: #747576;
$c-white:#fff;
$c-hardgrey: #404040;
$c-left-nav-visited: #6C7375;
$c-cm-profile-link: #007d96;
$c-advisor-dark-blue: #49697f;
$c-disclaimer-bg: #dededf;
$c-disclaimer-text: #191718;

$c-border-grey: #a59e92;
$c-lighter-slate:#81888e;
$c-slate:#61666b;
$c-lightslate:#547992;
$c-light-slate:#547992;

$c-tabs-primary:#005596;
$c-tabs-secondary:#61666b;
$c-tabs-tertiary:#2e2b27;
$c-tombstone-cta: #005d7d;

$c-top-nav: #676c73;
$c-cool-grey: #bcbcbc;

$c-tab-text: white !default;
$c-tab-bg: #005596 !default;
$c-tab-active-text: white !default;
$c-tab-active-bg: #80a1b6 !default;
$c-cm-lightblue: #008099;
$c-body: #61666b;

$c-statistic:#da8659;

$body-text: $c-body;
$text-color: $c-body;

$c-main-text: #695e4a;

$primary-nav-links: white;

$c-error-red: $c-red;
$c-header-nav-light-blue: #006F94;
$c-footer-nav-light-blue: #00506b;
$c-header-nav-text: #101820;
$c-ir-main-text: #101820;
// Bootstrap resets
$font-family-sans-serif: "effra", sans-serif;
$font-size-base: 16px;

$headings-font-family: "essonnes-display", serif;

$font-itcFranklinGothic: "ITC Franklink Gothic Std",sans-serif;

$font-centennialRoman: "Centennial Roman", Times New Roman, serif;


// Reset the mobile menu breakpoint
$grid-float-breakpoint: 992px;

$brand-primary: $c-lightgrey;
$btn-default-color: $c-orange;

$btn-border-radius-base: 3px;
$padding-base-vertical: 10px;
$padding-base-horizontal: 27px;

$input-border: transparent;
$input-border-radius: 0;
$input-border-size: 2px !default;

$btn-success-border: transparent;
$btn-info-border: transparent;
$btn-warning-border: transparent;
$btn-danger-border: transparent;
$btn-primary-border: transparent;
$btn-default-border: transparent;


//theme based variables
$header-background-color:$c-lightblue !default;
$header-bottom-background-color:$c-darkblue !default;
$main-search-background-color:$c-darkblue !default;
$footer-top-background-color:$c-darkblue !default;

$show-left-nav-arrows: false !default;
$show-cta-heading-arrows: true !default;
$show-read-more: false !default;
$show-cta-shadows: true !default;

$c-btn-primary:$c-orange !default;
$c-btn-default:$c-lightblue !default;

$borderColor:$c-orange !default;

$c-document-download-link:$c-lightblue !default;

$c-heading:$c-lightblue !default;
$c-link: $c-lightslate !default;
$c-styledlink: $c-link !default;

$c-brand-dark-blue:#00234c;
$c-royal-blue: #00234c;

$c-lightgreycta-bg: $c-paleblue !default;

$c-pagination-link: $c-darkgrey !default;
$c-pagination-link-active: $c-lightblue !default;

$c-breadcrumb-link: $text-color !default;
$c-breadcrumb-link-active: $c-darkblue !default;

$c-blog-bg: #f0f0ef !default;

$profile-padding: 20px !default;


// CTA Specific
$cta-standard-bg: white !default;
$cta-lightgrey-bg: #959595 !default;
$cta-royalblue-bg: #006385 !default;
$cta-green-bg: #008099 !default;
$cta-bordered-bg: white !default;

$cta-padding: 30px 34px !default;
$cta-padding-reduced: 24px 20px !default;
$cta-padding-sm-min: 45px 36px !default;

$cta-text-light: white !default;
$cta-text-dark: $text-color !default;
$cta-text-heading-light: white !default;
$cta-text-heading-dark: #006385 !default;
$cta-bg-no-mob: #006385;

$cta-green-text: white !default;
$cta-royalblue-text: white !default;
$cta-lightgrey-text: white !default;
$cta-lightgrey-heading-text: $cta-lightgrey-text !default;

$cta-bottom-margin: 30px !default;
$cta-homepage-stats-size: 42px !default;
$cta-equalise-bottom-margin: 15px !default;
$cta-bg-overlay-color: rgba(0, 0, 0, 0.2) !default;

$cta-bordered-border: 1px solid #BBBCBC !default;
$cta-bordered-text: #BBBCBC !default;

$cta-standard-heading-color: lightblue !default;

$cta-has-box-shadow: true !default;
$cta-box-shadow: 0 5px 10px 4px rgba(0,0,0,.12) !default;
$cta-box-shadow-active: 0 2px 5px 2px rgba(0,0,0,.12) !default;

$c-moonlight-teal: #005775;
$c-cm-primary-nav: #55595f;
$c-cg-careers-primary-nav: #fff;
$cta-green-button: #00856f;

// updated primary button color
$c-btn-primary-new: #1F6BB7;
$c-black: #1E1E1E;
$c-light-grey-new: #DDDDDD;
