.tabs {
    .tabs__container {

        .nav.nav-pills {
            margin: 20px 0;

            li {
                width: 100%;
                position: relative;
                background-color: $c-tab-bg;

                @media (min-width: $screen-sm-min) {
                    width: 24%;
                }

                @media (min-width: $screen-md-min) {
                    width: 16%;
                }

                @media(max-width:$screen-sm-max) {
                    margin-bottom: 5px;
                    margin-left: 0;
                }

                a {
                    font-family: "ITC Franklink Gothic Medium",sans-serif;
                    background-color: transparent;
                    color: $c-tab-text;
                    font-size: 14px;
                    font-weight: 700;
                    text-decoration: none;
                    border-radius: 0;
                }
            }

            li.active {
                background-color: $c-tab-active-bg;

                @media (min-width: $screen-sm-min) {
                    select { background-color: $c-tab-active-bg; color: $c-tab-active-text; }
                }

                a:after, select:after {
                    content: '';
                    position: absolute;
                    width: 0px;
                    height: 0px;
                    border-width: 16px 15px 0px 17px;
                    border-style: solid;
                    border-color: $c-tab-active-bg transparent transparent;
                    top: 39px;
                    right: 40%;

                    @media(max-width: $screen-xs-max) {
                        display: none;
                    }
                }
                a  {
                    color: $c-tab-active-text;
                }
            }

            select {
                height: 39px;
                margin-bottom: 0;
                width: 100%;

                &:after {
                    content: '';
                    display: none;
                }
            }
        }
    }

    .tab-content {
        &__body {
            padding: 15px 10px;
            @include clearfix();
        }

        .html-block {
            width: auto;
            min-width: 25%;
            /*padding-right: 15px;*/

            @media(max-width:$screen-md-max) {
                min-width: 50%;
            }

            @media(max-width:$screen-xs-max) {
                min-width: 100%;
                max-width: 100%;
                padding-right: 0;
            }
        }
    }
}
