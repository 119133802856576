
.blog__cta.transactions {
    box-shadow: none;

    .blog__summary .more {
        color: $c-main-text;

        &:hover {
            text-decoration: underline;

            &:after {
                content: ">";
                display: inline-block;
                margin-left: 5px;
                font-size: 16px;
                font-weight: normal;
                position: relative;
                top: 1px;
            }
        }
    }

    .blog__cta-img-bg {
        width: 100%;
        height: auto;
        text-align: center;
        position: relative;
        padding: 17px;
        padding-bottom: 12px;

        img {
            max-width: 100%;
            margin: 0 auto;
        }

        span.hr {
            height: 1px;
            width: 100%;
            background-color: #dad9d6;
            margin: 0 auto;
            display: inline-block;
        }
    }

    .blog__cta-body, .full-width .blog__cta-body {
        padding: 0 17px 5px;
        color: $c-darkgrey;

        .blog__cta-title {
            color: $c-darkgrey;
        }

        .read-more {
            color: $c-darkgrey;
            width: 100%;
            display: block;
            box-shadow: none;
            position: relative;
            margin-bottom: 15px;

            &:after {
                content: "";
                width: 20px;
                height: 15px;
                background: url(/assets/images/capital-markets/chevron.png) no-repeat 10px 0;
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                background-size: contain;
            }
        }

        .blog__summary {
            margin-bottom: 37px;
        }

        .blog__info {
            font-weight: bold;
            color: $c-darkgrey;
        }
    }

    .sector__container {
        background-color: $c-cool-grey;
        padding: 15px 17px;
        color: #fff;

        p {
            border-bottom: 1px solid #fff;
            padding-bottom: 9px;
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 9px;
            color: #fff !important;

            &:last-of-type {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        .cross-border {
            position: relative;
            display: block;
            width: 100%;

            &:after {
                content: '';
                width: 22px;
                height: 22px;
                background: url(/assets/images/capital-markets/gears.png) no-repeat 0 0;
                display: inline-block;
                background-size: cover;
                position: absolute;
                right: 0;
                bottom: 0;

                @media(min-width:$screen-sm-min) and (max-width:$screen-sm-max) {
                    right: -15px;
                }
            }
        }
    }
}

.footnote__container {

    .cross-border {
        position: relative;
        left: 40px;
        display: block;

        &:before {
            content: '';
            width: 30px;
            height: 30px;
            background: url(/assets/images/capital-markets/gears.png) no-repeat 0 0;
            display: inline-block;
            background-size: cover;
            position: absolute;
            left: -37px;
            bottom: -7px;
        }
    }

    .footnote-item {
        padding-left: 5px;
    }
}


a.btn.btn-primary.sector-btn {
    width: 100%;
    max-width: 323px;
    display: inline-block;
    margin: 0 0 15px 0;
    background-color: $c-top-nav;
    border-radius: 0;
    position: relative;
    text-align: left;

    &:after, &:hover:after {
        content: "";
        width: 10px;
        height: 15px;
        background: url(/assets/images/capital-markets/chevron-white.png) no-repeat 4px 1px;
        display: inline-block;
        position: absolute;
        top: 25%;
        right: 20px;
        background-size: contain;
        border-left: none;
    }

    &:hover:after {
        display: none !important;
    }
}
