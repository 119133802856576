﻿.accordion-block.accordion-block__careers {
    margin-left: 15px;
    margin-right: 15px;

    @media(min-width: $screen-sm-min) {
        margin-left: 0;
        margin-right: 0;
    }

    .btn {
        margin: 15px 0;
    }
}

.careers-filter {
    &__container {
        padding: 30px 15px;
        text-align: left;
        margin: 50px 0;

        @media(min-width: $screen-sm-min) {
            text-align: right;
            margin: 0;
        }
    }


    &-item__container {
        display: block;


        @media(min-width: $screen-sm-min) {
            display: inline-block;
        }

        select {
            height: 39px;
            margin-bottom: 0;
            width: 100%;
            border-radius: 3px;
            padding: 5px 15px;
            margin: 15px 0;

            @media(min-width: $screen-sm-min) {
                min-width: 220px;
                max-width: 220px;
                margin: 0;
            }
        }

        label {
            padding: 7px 7px 0 7px;
            display: block;

            @media(min-width: $screen-sm-min) {
                display: inline-block;
                padding: 7px;
            }
        }

        .btn {
            position: relative;
            top: 13px;
            margin-left: 30px;
            height: 39px;
        }
    }
}