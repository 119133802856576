.main-content {
    overflow-x: hidden;
    #content > .container {
        padding-top: 20px;
    }
}

 .row.display-flex {
	display: flex;
	flex-wrap: wrap;
}

.row.display-flex > [class*='col-'] {
	display: flex;
	flex-direction: column;

	@media(max-width:$screen-sm-max) {
		width: 100%;
	}
}
