.mobile-nav {
    background-color: white;
    position: absolute;
    bottom: 66px;
    left: 0;
    right: 0;
    width: 100%;
    display: none;

    &-open {
        overflow-x: hidden;
    }

    &__close {
        font-size: 18px;
        display: block;
        padding: 15px;
        text-align: center;
    }

    &__inner {
        text-align: center;
        padding: 20px 30px;
        min-height: 150px;

        p {
            font-size: 22px;
            line-height: 1.2;
        }

        a {
            text-decoration: underline;
            font-size: 18px;
            display: block;
        }
    }

    &__toggle {
        display: block;
        width: 25px;
        text-align: center;
        display: block;
        float: right;
        position: relative;
        top: 0;
        right: 10px;

        @media (min-width: $screen-sm-min) {
            display: none;
        }

        .icon-bar {
            border: 1px solid white;
            margin-bottom: 5px;
            display: block;
            width: 100%;
        }

        &-text {
            color: white;
            font-size: 12px;
            margin-left: -2px;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .primary-nav__search-wrapper {
        padding: 0;
        text-align: center;
        margin-bottom: 15px;
        position: relative;

        .primary-nav__search {
            width: 100%;
            border: none;
            padding: 10px 15px;
            padding-right: 45px;
            border-radius: 3px;
            margin-bottom: 0;

            &-btn {
                color: white;
                background: url(/assets/images/search-icon-blue.png) no-repeat
                    50%;
                width: 30px;
                height: 42px;
                display: inline-block;
                position: absolute;
                top: 0;
                right: 10px;
            }
        }
    }

    .primary-nav__item--actions {
        padding: 20px;
    }

    ul.primary-nav__list {
        position: relative;
        background-color: $c-darkblue;
        overflow-y: hidden;
    }

    .mobile-nav__fullheight {
        background-color: $c-darkblue;
        height: 100vh;
        .header-top__info {
            padding: 20px 20px 0;
        }
    }

    .primary-nav {
        &_btn-close {
            border: none;
            color: white;
            background-color: $c-orange;
            height: 80px;
            width: 75px;
            text-align: center;
            position: absolute;
            right: 0;
            z-index: 78;

            &:before {
                content: 'x';
                color: #fff;
                font-size: 32px;
                text-align: center;
                display: block;
                font-weight: bold;
            }
        }

        &.is-active {
            .primary-nav_btn-close {
                left: 300px;
                @media (max-width: 320px) {
                    left: 240px;
                }
            }
        }
    }

    .primary-nav_overlay {
        width: 0;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        opacity: 0;
        /*left: -300px;*/
        top: 0;
        bottom: 0;
        transition: width 0s ease 0.3s, opacity 0.3s;
    }

    .primary-nav.is-active .primary-nav_overlay {
        width: calc(
            100vw + 300px
        ); // Size of the window plus the left offset size
        opacity: 1;
        transition: width 0s, opacity 0.3s;
        height: 10000px;
        z-index: -1;
    }

    .primary-nav_mobile-header {
        background-color: $c-lightblue;
        position: relative;
        height: 80px;
        padding: 15px 10px;

        &-link {
            display: inline-block;
            color: white;
        }
    }

    .primary-nav_mobile-telephone {
        color: white;

        &:hover {
            color: white;
        }
    }

    .primary-nav_mobile-logo {
        max-width: 210px;
        vertical-align: text-bottom;
        margin-left: 10px;
        margin-bottom: 0;
    }

    .primary-nav_mobile-header p {
        display: inline-block;
        text-transform: uppercase;
        vertical-align: top;
        padding-top: 15px;
        padding-left: 15px;
        color: white;
        font-weight: normal;
    }
}

@media (min-width: $screen-sm-min) {
    .primary-nav_btn-close {
        display: none;
    }

    .mobile-nav__fullheight {
        height: auto !important;
    }
}

.header-top.umbrella {
    .mobile-nav__toggle .icon-bar {
        border: 1px solid #006385;
        margin-bottom: 5px;
        display: block;
        width: 100%;
    }
}
