.social-share,
.html-block .social-share {
    padding-left: 0;
    margin-top: -20px;
    margin-bottom: 30px;
    h2 { font-size: 18px; margin-bottom: 0; }
    li {
        display: inline-block;
        margin-right: 25px;
        a {
            display: block;
            font-size: 0;
            box-shadow: none;
        }
    }

    &__linkedin {
        width: 41px;
        height: 38px;
        background: url(/assets/images/social-share-linkedin.png) no-repeat;
    }

    &__twitter {
        width: 39px;
        height: 39px;
        background: url(/assets/images/social-share-twitter-x.png) no-repeat;

    }

    &__facebook {
        width: 39px;
        height: 39px;
        background: url(/assets/images/social-share-facebook.png) no-repeat;

    }
}
