.html-block {
	
	#ot-sdk-cookie-policy div.ot-sdk-cookie-policy {
	    font-size: inherit;

	    h3#cookie-policy-title {
	        font-weight: 600;
	        font-size: inherit;
	    }

	    #cookie-policy-description {
	        font-size: inherit;
	    }

	    h4.ot-sdk-cookie-policy-group {
	        font-size: inherit;
	        font-weight: 600;
	    }

	    .ot-sdk-cookie-policy-group-desc {
	        font-size: inherit;
	    }

	    table th, table td {
	        border: inherit;
	        border-bottom: 1px solid #d7d7d7;
	        border-right: 1px solid #d7d7d7;
	        word-break: break-all;
	    }

	    .ot-table-header {
	        font-size: inherit;
	    }

	    #cookie-policy-description,
	    .ot-sdk-cookie-policy-group-desc,
	    .ot-cookies-td-content,
	    .ot-cookies-td-content a,
	    .ot-cookies-type-td-content,
	    .ot-life-span-td-content,
	    .ot-host-td,
	    .ot-host-td a {
	        font-size: inherit;
	        background: none;
	    }
	}
}