.c-profile-card {
	@include font-effra;
	display: flex;
	flex-flow: row nowrap;
	padding: t-spacing(8) t-spacing(4);
	margin-bottom: t-spacing(6);
	border: solid 1px #dfdfdf;
	background-color: $neutral-white-100;

	@media(max-width:$screen-sm-max) {
		flex-flow: column nowrap;
		padding: t-spacing(4);
	}

	.c-profile-card__info {
		flex: 1;

		@media(max-width:$screen-sm-max) {
			order: 2;
		}
	}

	.c-profile-card__title {
		font-size: 22px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.27;
		letter-spacing: normal;
		color: $neutral-grey-100;
	}

	.c-profile-card__details {
		list-style-type: none;
		margin-left: 2.5em;
		padding-left: 0;
		margin-top: 0;
		margin-bottom: 1rem;

		a {
			color: $primary-blue-60;
			border-bottom: 1px solid transparent;

			&:hover {
				color: $primary-blue-80;
				border-bottom-color: $primary-blue-80;
			}

			&:focus {
				border-bottom-color: $focus-orange-100;
			}

			&:active {
				color: $primary-blue-100;
				border-bottom-color: $primary-blue-100;
				box-shadow: none;
			}
		}

		li {
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			color: $primary-blue-60;
			position: relative;
			margin-bottom: t-spacing(3);
			line-height: 2;

			.icon {
				left: -2.5em;
				position: absolute;
				text-align: center;
				width: 2em;
				line-height: inherit;
			}
		}
	}

	.c-profile-card__cta {
		&.btn { // This is needed to override the bootstrap btn styles.
			@include font-effra;
			position: relative;
			min-width: 0;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: $primary-blue-60;
			text-align: left;
			padding: 0;

			&::after {
				content: '';
				display: block;
				height: 1px;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 24px;
				background-color: transparent;
			}

			&:hover {
				color: $primary-blue-80;

				&::after {
					background-color: $primary-blue-80;
				}
			}

			&:focus {
				&::after {
					background-color: $focus-orange-100;
				}
			}

			&:active {
				color: $primary-blue-100;
				box-shadow: none;

				&::after {
					background-color: $primary-blue-100;
				}
			}
		}

		.bi {
			font-size: 24px;
			display: inline-block;
			vertical-align: bottom;
		}
	}

	.c-profile-card__avatar {
		@media(max-width:$screen-sm-max) {
			margin-bottom: t-spacing(4);
			text-align: center;
			order: 1;
		}

		img {
			height: 140px;
			width: auto;
		}
	}
}
