.main-content .cta {
	margin-bottom: $cta-bottom-margin;

	[data-equalise='cta-body'] {
		margin-bottom: $cta-equalise-bottom-margin;
	}

	&__body {
		padding: $cta-padding;
	}

	&__heading {
		&--linked { // Refers to the actual h2 heading
			color: inherit;
			padding-right: 15px;
		}

		&-anchor {
			color: inherit;
			transition: all ease-in 0.3s;

			&:hover {
				color: inherit;
				text-decoration: underline !important;
			}

			@if $cta-has-box-shadow == true {
				&:after {
					content: "\203a";
					color: inherit;
					display: inline-block;
					font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', sans-serif;
					font-style: normal;
					font-weight: 200;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					position: relative;
					font-size: 1em;
					top: 0;
					padding-left: 10px;
					margin-right: -15px;
				}
			}
		}
	}

	&--standard,
	&--bordered,
	&--bordered,
	&--lightgrey {
		h1, h2, h3, h4, h5 {

			> a,
			&.cta__heading--linked {

				&:after {
				}
			}
		}
	}
	// With background image
	&--bg {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		position: relative;
		z-index: 10;
		color: $cta-text-light;
		padding: 20px 15px;
		border: 1px solid transparent;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $cta-bg-overlay-color;
			z-index: -1;
		}

		@media (min-width: $screen-sm-min) {
			padding: $cta-padding-sm-min;
		}

		h1, h2, h3, h4, h5, p, a:not(.btn) {
			color: $cta-text-light;
		}

		h2 {
			margin-bottom: 25px;
		}
	}
	// Colour variants
	&--bordered {
		background-color: $cta-bordered-bg;
		color: $cta-text-dark;
		border: $cta-bordered-border;

		h1, h2, h3, h4, h5, p, a:not(.btn) {
			color: $cta-royalblue-bg;
		}

		p {
			color: $c-ir-main-text;
		}

		h1, h2, h3, h4, h5 {
			> a,
			.cta__heading--linked {
				color: $cta-royalblue-bg;
			}
		}

		.cta__img {
			margin: -1px;
			max-width: calc(100% + 2px);
		}
	}


	&--standard {
		background-color: $cta-standard-bg;
		color: $cta-text-dark;
		border: 1px solid transparent;

		p, a:not(.btn) {
			color: $c-ir-main-text;
		}

		@if $cta-has-box-shadow == true {
			box-shadow: $cta-box-shadow;
		}

		h1, h2, h3, h4, h5, [data-equalise=cta-heading] {
			color: $cta-text-heading-dark;
		}
	}


	&--royalblue {
		background-color: $cta-royalblue-bg;
		color: $cta-royalblue-text;
		border: 1px solid transparent;

		h1, h2, h3, h4, h5, .cta__body, p, a:not(.btn) {
			color: $cta-royalblue-text;
		}
	}

	&--green {
		background-color: $cta-green-bg;
		color: $cta-green-text;
		border: 1px solid transparent;

		h1, h2, h3, h4, h5, .cta__body, p, a:not(.btn) {
			color: $cta-green-text;
		}
	}

	&--lightgrey {
		color: $cta-lightgrey-text;
		background-color: $cta-lightgrey-bg;
		border: 1px solid transparent;
		/* text-align: center; */
		h1, h2, h3, h4, h5 {
			color: $cta-lightgrey-heading-text;
		}

		.cta__body, p, a:not(.btn) {
			color: $cta-lightgrey-text;
		}
		/* h3 {
                display: none;
            } */
		/* p {
                font-size: 20px;
                margin-bottom: 5px;
            } */
		span.homepage-stats {
			color: $cta-text-light;
			font-size: $cta-homepage-stats-size;
			font-weight: bold;
		}
	}

	&--wideblue {
		color: $cta-text-light;

		.cta__body {
			//max-width: 800px; commented out due to width restrictions not allowing btn to float absolutely right - RM
			margin: 0 auto;
			color: $cta-text-light;
		}

		.cta__title {
			font-family: "Centennial Roman", serif;
			font-size: 17px;
			position: relative;
			top: auto;
			margin-bottom: 20px;
			display: inline-block;
			color: $cta-text-light;

			@media (min-width: $screen-md-min) {
				top: 7px;
				display: inline;
			}
		}
	}

	&--bg-no-mob {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		position: relative;
		z-index: 10;
		color: $cta-bg-no-mob;
		padding: 20px 15px;
		border: 1px solid transparent;

		@media (min-width: $screen-sm-min) {
			padding: 0;
		}

		@media (min-width: $screen-md-min) {
			padding: 0;
		}

		@media (min-width: $screen-lg-min) {
			padding: 0;
		}

		&:before {
			content: "";
			display: block;
			position: relative;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $cta-bg-overlay-color;
			z-index: -1;
		}

		.cta-body {
			.cta_title {
				line-height: 1.2;
				color: $cta-text-light;

				@media (min-width: $screen-sm-max) {
					color: $cta-bg-no-mob;
				}
			}

			@media (max-width: $screen-md-min) {
				border: 2px solid $cta-bg-no-mob;
				padding: 20px;

				.cta_title {
					color: $cta-bg-no-mob;
				}
			}

			@media (min-width: $screen-md-min) {
				position: absolute;
				top: 30px;
				padding: 50px;
				width: 40%;
				border: none;

				.cta_title {
					line-height: 1.2;
					color: $cta-text-light;
				}

				p {
					color: $cta-text-light;
					margin-bottom: 60px;
				}
			}

			@media (min-width: $screen-lg-min) {
				position: absolute;
				top: 30px;
				padding: 50px;
				width: 40%;
				border: none;

				.cta_title {
					line-height: 1.2;
				}

				p {
					color: $cta-text-light;
					color: $cta-text-light;
					margin-bottom: 60px;
				}
			}
		}

		@media (min-width: $screen-sm-min) {
			padding: $cta-padding-sm-min;
		}
	}

	[data-equalise="cta_content"] {
		margin-bottom: 15px;
	}
}

// When CTA is located in a sidebar
.main-content .sidebar .cta {
    padding: 0;

    .cta__body {
        padding: 20px 15px;
    }

    &.cta--bordered {
        .title {
            font-size: 18px;
            color: $cta-text-dark;
            margin-bottom: 15px;
        }
        .cta__body {
            padding: 23px 20px;
        }
    }
}


.cta-tiles {
    &__container {
        .pull {
            margin-top: -100px;

            @media(max-width:$screen-sm-max) {
                margin-top: 0;
            }
        }
    }

    &__pod {
        padding: 20px;
        border-radius: 4px;
        margin-bottom: 20px;
        min-height: 200px;
        color: $cta-text-light;

        p {
            color: $cta-text-light;
        }

        h3 {
            margin-bottom: 5px;
            color: #fff;
            font-weight: 400;
            font-size: 22px;

            @extend .cta__heading--linked;

            a {
                color: #fff;
                transition: all ease-in 0.3s;
                @extend .cta__heading-anchor;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        &.tall {
            min-height: 300px;
        }
    }
}
