.hero-banner {
    background-position: 50% 0%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    min-height: 150px;
    margin-bottom: 10px;
    color: white;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

    div:not(.carousel) > & {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;

        background-size: 768px;

        @media (min-width: $screen-sm-min) {
            background-size: cover;
        }
    }

    h1,h2,h3,h4,h5 { color: white; }
    @media (min-width: $screen-sm-min) {
        /*min-height: 326px;*/
        height: 0;
        padding-top: 4%;
        padding-bottom: 16%;
        min-height: 330px;
        margin-bottom: 0;

        display: flex;
        align-items: center;
        /*justify-content: center;*/
        padding-top: 0;
        padding-bottom: 0;
        flex-direction: column;
        justify-content: center;
    }
    h1 {
        position: absolute;
        font-size: 34px;
        color: white;
        top: 60px;
        @media (min-width: $screen-sm-min) {
            top: auto;
            bottom: 40px;
        }
    }

    &__overlay {
        margin-top: 0.5em;
    }

    .hero-overlay {
        position: relative;
        color: white;

        h1 {
            position: absolute;
            top: 80px;
            @media(max-width: $screen-xs-max) {
                top: 40px;
                font-size: 24px;
            }
        }
    }

    > img {
        display: block;
        width: 100%;
        height: auto;
    }

    &.alert {
         padding:0;
        img {
            @media(max-width: $screen-xs-max) {
                height: 130px;
                padding:0;
            }
        }
    }

    .hero-text {
        color: white;
        font-family: "Centennial Roman", "Times New Roman", serif;

        width: auto;
        display: inline-block;
        text-align: left;
        font-size: 16px;

        @media (min-width: 500px) {
            font-size: 16px;
        }

        @media (min-width: $screen-sm-min) {
            font-size: 26px;
        }

        @media (min-width: $screen-md-min) {
            font-size: 32px;
        }

        @media (min-width: $screen-lg-min) {
            font-size: 38px;
        }

        &.topline{
            font-family: essonnes-display, serif;
            &--small {
                .head {
                    margin-bottom: 0em;
                    display: inline-block;
                }

                .highlight {
                    display: inline-block;
                    margin-top: -0.18em;
                }
            }

            &--large {
                .head {
                    display: inline-block;
                    margin-bottom: 0em;
                }

                .highlight {
                    display: inline-block;
                    margin-top: 0em;
                }
            }
        }

        .head {
            font-size: 1em;
            line-height: 1em;
            display: inline-block;
            position: relative;
            font-family: essonnes-display, serif;
        }

        .highlight {
            font-size: 3em;
            line-height: .8em;
            display: inline;
            font-family: essonnes-display, serif;
            &--7x {
                font-size: 7em;
                line-height: .7;
            }
        }

        .tail {
            font-size: 1em;
            line-height: 1em;
            display: inline;
            margin-left: 1.5em;
            font-family: essonnes-display, serif;
        }

        .head >div,
        .highlight >div,
        .tail >div{
            display: inline;
        }
        &__wrapper {
            position: relative;

            @media (min-width: $screen-sm-min) {
                top: 20px;
                left: 20px;
                right: 20px;
                display: flex;
                /*align-items: center; */
                justify-content: center;
                position: relative;
                top: initial;
                left: initial;
                right: initial;
                -ms-flex-pack: space-between;
            }

        }
    }

    &__mobile-content {
        background-color: $c-darkblue;
        color: white;
        text-align: center;
        padding: 35px 30px 25px 20px;
    }

    &__caption {
        position: absolute;
        top: 5px;
        color: white;
        text-align: right;
        opacity: 0.75;

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            padding-right: 30px;
        }

        @media (min-width: $screen-md-min) {
            margin-left: -15px;
            margin-right: -15px;
            font-size: 22px;
        }
    }

    .carousel &.dark,
    &.dark {
        &,
        .hero-text,
        .hero-overlay,
        .hero-text__wrapper .hero-text,
         h1,h2,h3,h4,h5,
        .hero-banner__caption {
            color: $c-darkgrey;
        }
    }
}
