// $form-select-indicator: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$form-select-indicator: url("data:image/svg+xml,<svg viewBox='0 0 24 25' xmlns='http://www.w3.org/2000/svg'><g fill='none' fill-rule='evenodd'><path fill='#1F6BB7' d='M7.41 9.208L12 13.979 16.59 9.208 18 10.677 12 16.927 6 10.677z' transform='translate(-373 -7112) translate(135 7072) translate(238 40)'/><path d='M0 0.26L24 0.26 24 25.26 0 25.26z' transform='translate(-373 -7112) translate(135 7072) translate(238 40)'/></g></svg>"); .c-tombstone-filter-container {
	display: flex;
	flex-flow: row;

	@media(max-width:$screen-sm-max) {
		flex-flow: column;
	}

	> div {
		flex: 1;
		padding-right: 30px;

		@media(max-width:$screen-sm-max) {
			padding-right: 0;
			margin-bottom: t-spacing(4);
		}

		&:last-of-type {
			padding-right: 0;
		}
	}
}

.c-tombstone-filter__form-label {
	@include font-effra;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	color: $c-black;
	margin-bottom: t-spacing(2);
}

.c-tombstone-filter__form-select {
	display: block;
	width: 100%;
	height: t-spacing(10);
	padding: t-spacing(2) 0 t-spacing(2) t-spacing(2);
	@include font-effra;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: $c-btn-primary-new;
	vertical-align: middle;
	background-color: $neutral-grey-10;
	background-image: escape-svg($form-select-indicator);
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 24px 25px;
	border: none;
	border-bottom: 1px solid $c-btn-primary-new;
	appearance: none;

	&:hover {
		background-color: $neutral-grey-20;
		border-bottom-color: $primary-blue-80;
	}

	&:focus {
		border-bottom-color: $focus-orange-100;
		border-bottom-width: 2px;
	}

	&:active {
		border-bottom-color: $primary-blue-100;
		background-color: $neutral-grey-20;
	}

	&:disabled {
		color: $neutral-grey-80;
		border-bottom-color: $neutral-grey-80;
	}
	// Remove outline from select box in FF
	&:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 $neutral-grey-60;
	}
	// This is to remove the arrow on IE11
	&::-ms-expand {
		display: none;
	}
}

.c-tombstone-filter__reset-btn {

	&:active {
		box-shadow: none;
	}

	&.btn { // This is needed to override the bootstrap btn styles.
		@include font-effra;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: $c-btn-primary-new;
		text-align: right;
		padding: 0;
	}

	.bi {
		font-size: 24px;
		display: inline-block;
		vertical-align: bottom;
	}
}

.c-tombstone-filter__sort-select {
	max-width: 170px;
	margin-left: auto;
	margin-bottom: t-spacing(8);

	@media(max-width:$screen-sm-max) {
		max-width: none;
		margin-bottom: t-spacing(6);
	}
}

.c-tombstone-filter-cta-container {
	margin-top: t-spacing(8);
	text-align: right;
}

.c-tombstone-filter__find-btn {
}
