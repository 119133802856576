$primary-teal-100: #009bbb;
$primary-green-100: #1ab399;
$neutral-grey-100: #363c43;
$focus-orange-100: #ffa300;
$neutral-grey-80: #939597;
$neutral-grey-60: #d7d7d7;
$neutral-grey-40: #e4e4e4;
$neutral-grey-20: #f1f1f1;
$neutral-grey-10: #f9f9f9;
$neutral-white-100: #ffffff;
$primary-blue-100: #0d3e53;
$primary-blue-80: #104e69;
$primary-blue-60: #135f7f;