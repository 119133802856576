.video-container {
    position: relative;
	padding-bottom: 56.25%;
	height: 0;    
    display: block;    
    overflow: hidden;
    margin-bottom:25px;
    &:before {
        content: "";
        display: block;
        padding-top: 56.25%;
    }

    iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
} 
