.header {
	&__logo {
		position: relative;

		@media (min-width: $screen-sm-min) {
			top: 3px;
			position: absolute;
			z-index: 1;
		}

		> img {

			@media (min-width: $screen-sm-min) {
				width: 198px;
				height: auto;
			}
		}
	}

	&-top {
		background-color: $header-background-color;
		padding: 11px 0 15px;
		/*min-height: 114px;*/
		@media (min-width: $screen-sm-min) {
			padding: 22px 0 20px;
		}

		&__info {
			font-size: 14px;
			color: white;
			margin-bottom: 8px;
			display: none;

			@media (min-width: $screen-sm-min) {
				display: inline-block;
			}

			a {
				color: white;
				font-weight: bold;
			}
		}

		.btn--lock {
			max-width: 225px;
			position: relative;
			top: -5px;
		}

		.tertiary {
			margin-top: 5px;
			text-align: right;

			@media (min-width: $screen-md-min) {
				margin-top: 2px;
				float: right;
				text-align: left;
			}

			&__item {
				margin-left: 20px;

				&:first-child {
					margin-left: 0;
				}
			}

			&__link {
				color: white;

				&--active, &:hover, &:focus {
					position: relative;
					text-decoration: underline;

					&:before {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						width: 100%;
						display: block;
						top: 23px;
					}
				}
			}
		}

		.header & .site-search {
			display: none;

			@media (min-width: $screen-sm-min) {
				display: inline-block;
				margin-right: 20px;
				margin-bottom: 5px;

				&__input {
					background-color: transparent;
					margin-right: -35px;
					padding-right: 35px;
				}
			}
		}

		a.lock {
			color: white;
			font-size: 16px;
			font-weight: bold;
			margin-left: 17px;

			&:before {
				content: "";
				display: inline-block;
				margin-right: 7px;
				background: url(/assets/images/lock.png) no-repeat 0 0;
				width: 13px;
				height: 18px;
				position: relative;
				top: 2px;
			}
		}
	}

	&-bottom {
		background-color: $header-bottom-background-color;

		.center-content {
			text-align: center;
		}

		.header & .site-search {
			$search-border-colour: rgba(255,255,255,1);



			@media (min-width: $screen-sm-min) {
				width: 1%;
				position: relative;
				display: inline-block;

				> .site-search__input {
					position: absolute;
					top: 13px;
					right: 43px;
					display: none;
					border-top: 1px solid $search-border-colour;
					border-bottom: 1px solid $search-border-colour;
					border-left: 1px solid $search-border-colour;
					padding: 4px 4px;
					height: 32px;

					@media (min-width: $screen-sm-min) {
						right: 37px;
					}

					@media (min-width: $screen-md-min) {
						right: 48px;
					}
				}

				> .site-search__btn {
					background-position: 50%;
					height: 32px;

					@media (min-width: $screen-sm-min) {
						right: 14px;
						top: 13px;
					}

					@media (min-width: $screen-md-min) {
						right: -10px;
					}
				}

				&.is-active {
					> .site-search__input {
						display: block;
					}

					> .site-search__btn {
						opacity: 1;
						border-right: 1px solid $search-border-colour;
						border-top: 1px solid $search-border-colour;
						border-bottom: 1px solid $search-border-colour;

						@media(min-width: $screen-lg-min) {
							background-position: 7px 50%; //Makes up for the border that only exists when is-active (desktop) - RM
						}
					}
				}
			}

			@media (min-width: $screen-lg) {
				float: right;
				right: 30px;
			}
		}

		.header-top__info {
			display: block;
			padding: 20px;
			color: white;

			@media (min-width: $screen-sm-min) {
				display: none;
			}

			p, p a {
				color: white;
				font-size: 15px;
				font-weight: bold;
			}
		}
	}

	.site-search {
		//width: 19.5%;
		width: 16.5%;
		text-align: right;

		&__input {
			border: none;
			background-color: $main-search-background-color;
			color: white;
			max-width: 130px;
			margin-bottom: 0;
			padding: 4px 0px;
			padding-right: 20px;
			margin-right: -34px;

			@media (min-width: $screen-sm-min) {
				margin-right: -40px;
			}

			@media (min-width: $screen-md-min) {
				margin-right: -49px;
			}

			&::placeholder {
				color: white;
				font-size: 16px;
				font-weight: bold;
			}

			&:active, &:focus {
				outline: 0;
				border-bottom: 1px solid white;
			}
		}

		&__btn {
			color: white;
			background: url($imgPath + 'search-icon.png') no-repeat 50%;
			width: 30px;
			height: 30px;
			display: inline-block;
			position: relative;
			top: 10px;

			@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				background-position: 100% 0;
				right: 17px;
			}

			@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
				right: -13px;
			}

			&:hover {
				opacity: .75;
			}
		}
	}

	.wealth-login__placeholder {
		.btn {
			position: relative;
			top: -5px;
		}
	}


	.external-links {
		display: none;

		&--mobile {
			@media (max-width: $screen-xs-max) {
				display: block;
				padding: 0 20px;
				margin-bottom: 20px;
			}
		}

		&--desktop {
			@media (min-width: $screen-sm-min) {
				display: inline-block;
			}
		}
	}
}


//REFACTOR!!!
.wealth-login__placeholder {
    display: inline-block;
    margin-left: 20px;

    > p {
        margin-bottom: 0;
    }
}

.canaccord-external-links__wrapper.col-xs-4.col-sm-6.pull-right {
    text-align: right;
    white-space: nowrap;
}

.canaccord-external-links__container {
    display: inline-block;
    font-weight: 600;
    color: white;

    a {
        color: white;
    }
}


.header-top__info a.anchor-align {
    display: block;
    margin-right: 10px;
    margin-bottom: 5px;

    @media (min-width: $screen-sm-min) {
        display: inline-block;
        margin-bottom: 0;
    }
}

.header .site-search {
    @media (max-width: $screen-sm-min) {
        display: none;
    }
}

.header-top.umbrella {

	.header-top__info a {
		color: #008099;
		font-family: effra,sans-serif;
		font-weight: 700;
		font-style: normal;
	}

	p {
		color: #62656a
	}

	.mobile-nav__toggle-text {
		font-size: 12px;
		color: #006385;
	}
}

.header-bottom.umbrella {
	background-color: none;

	@media (max-width: 767px) {

		.primary-nav_mobile-header {
			background-color: #fff;
			position: relative;
			height: 80px;
			padding: 15px 10px;
		}

		.primary-nav_btn-close {
			border: none;
			color: #fff;
			background-color: #00856f;
			height: 80px;
			width: 75px;
			text-align: center;
			position: absolute;
			right: 0;
			z-index: 78;
		}

		.mobile-nav__fullheight {
			position: relative;
			background-color: #006385;
			color: #fff;
		}

		.header-top__info p a {
			color: #fff;
			font-size: 15px;
			font-weight: 700;
		}

		.primary-nav_btn-close {
			border: none;
			color: white;
			background-color: #00856f;
			height: 80px;
			width: 75px;
			text-align: center;
			position: absolute;
			right: 0;
			z-index: 78;

			&:before {
				content: "x";
				color: #fff;
				font-size: 32px;
				text-align: center;
				display: block;
				font-weight: bold;
			}
		}
	}
}
