.language-selector {
    display: inline-block;
    position: relative;
    width: 100%;

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        width: 19%;
    }

    @media (min-width: $screen-md-min) {
        width: 15.5%;
    }

    &.is-active {
        .language-selector__panel {
            display: block;
            width: 100%;
        }
    }

    &--mobile .language-selector {
        @media (max-width: $screen-sm-max) {
            display: block !important;
        }
    }

    .header-bottom & {
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

    &__link {
        font-size: 15px;
        text-align: left;
        padding: 2px 0;
        display: block;
        position: relative;
        font-weight: bold;
        -webkit-appearance: none !important;
        background-color: transparent;
        border: none;
        color: $c-header-nav-text;
        margin-bottom: 5px;

        &:hover, &:focus, &:active {
            text-decoration: underline;
        }
    }

    &__list {
        margin: 12px 0 10px;
        padding: 10px;
        padding-top: 0;
        background-color: white;
        list-style: none;
        padding-left: 20px;
        border-radius: 0 0 6px 6px;
    }

    &__selected {
        color: white;
        padding: 15px 10px 15px 32px;
        font-weight: 700;
        font-size: 14px;
        display: inline-block;
        cursor: pointer;

        &:hover, &:focus {
            text-decoration: underline;
        }

        .language-selector.is-active & {
            background-color: white;
            border-radius: 6px 6px 0 0;
            color: $c-darkblue;
            text-decoration: none;

            &:before {
                background-image: url(/assets/images/location-icon-active.png);
            }

            &:after {
                background-image: url(/assets/images/nav-dropdown-active.png);
            }
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            padding: 12px 8px 20px 30px;
        }

        @media (min-width: $screen-md-min) {
            padding: 12px 12px 20px 32px;
            min-width: 130px;
        }

        &:before {
            content: "";
            background: url(/assets/images/location-icon.png) no-repeat 0 0;
            display: inline-block;
            margin-right: 3px;
            width: 18px;
            height: 18px;
            position: absolute;
            left: 7px;
        }

        &:after {
            content: "";
            background: url(/assets/images/nav-dropdown.png) no-repeat 0 0;
            display: inline-block;
            margin-left: 7px;
            color: white;
            width: 12px;
            height: 7px;
            position: relative;
            bottom: 1px;
        }
    }

    &__panel {
        display: none;
        color: white;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0;
        min-width: 131px;
        max-width: 300px;
        z-index: 20;
        list-style: none;
        margin-left: 0;
        top: 24px;

        @media (min-width: $screen-sm-min) {
            top: 27px;
            margin-left: 50%;
            transform: translateX(-50%);
            border-radius: 0 0 6px 6px;
        }
    }
}
