

.main-content .left-nav, .left-nav {
    list-style: none;
    padding-left: 0;
    margin-bottom: 30px;
    display: none;

    @media (min-width: $screen-sm-min) {
        max-width: 260px;
        display: block;
        padding-top: 22px;
    }

    &__item {
        display: block;

        &--parent > a {
            color: $cta-royalblue-bg;
            font-weight: 600;

            &:after {
                position: absolute;
                right: 0;
                top: -3px;
                content: url(/assets/images/left-nav-parent-active.png);
                color: $c-darkblue;
                display: inline-block;
            }
        }

        &--active {
            color: $c-lightblue;

            &:after {
                content: url(/assets/images/left-nav-active.png);
                color: $c-lightblue;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }

    &__link {
        font-size: 16px;
        text-decoration: none;
        color: $cta-royalblue-bg;
        margin-bottom: 20px;
        display: block;
        position: relative;
        font-family: effra, sans-serif;
        padding-right: 10px;

        &--active {
            color: $c-left-nav-visited;

            &:after {
                content: url(/assets/images/left-nav-active.png);
                color: $c-lightblue;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 0;
            }
        }


        &--selected {
            color: $c-lightblue;

            &:after {
                content: url(/assets/images/left-nav-selected.png);
                color: $c-lightblue;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        &:hover {
            color: $cta-royalblue-bg;
            text-decoration: underline;
        }
    }

    &__lower {
        padding-left: 20px;
    }
}
