.carousel {
    @media (min-width: $screen-sm-min) {
        min-height: 430px;
    }



    .hero-text__wrapper {
        position: relative;

        @media (min-width: $screen-sm-min) {
            top: 10px;
        }

        @media (max-width: $screen-xs-max) {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .hero-text {
                font-size: 18px;

                .highlight {
                    font-size: 2em;
                }
            }
        }
    }

    .hero-banner {
        margin-bottom: 0;
        background-size: cover;

        @media (max-width: $screen-xs-max) {
            padding: 0;
            height: 100%;

            > .container {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                height: 100%;
            }
        }

        @media (min-width: $screen-sm-min) {
            min-height: 430px;
        }

        &__bg {
            display: none !important;
            width: 100%;

            @media (max-width: $screen-xs-max) {
                display: block !important;
            }
        }

        &__overlay {
            @media (max-width: $screen-xs-max) {
                padding: 0 20px 40px;
                color: #fff;
                margin-left: -15px;
                margin-right: -15px;
                width: auto;
                margin-top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                p.narrow {
                    margin-bottom: 1.5em !important;
                    margin-top: 0 !important;
                }
            }
        }
    }

    &__inner {
        overflow: hidden;
        left: 0;
        top: 0;
        width: 100%;

        /*@media (min-width: $screen-sm-min) {
            position: absolute;
        }*/
    }

    &__item {
        @media (max-width: $screen-xs-max) {
            height: 320px;
        }
    }

    .slick {
        &-dotted.slick-slider {
            @media (max-width: $screen-xs-max) {
                margin-bottom: 0;
            }
        }

        &-prev, &-next {
            height: 80px;
            width: 80px;
            background-size: contain;
            z-index: 100;

            @media (max-width: $screen-xs-max) {
                bottom: -21px;
                top: auto;
                height: 50px;
                width: 50px;
                background-size: 60% !important;
            }

            &:before {
                display: none;
            }

            &:hover {
                opacity: .75;
            }

            &.slick-disabled {
                display: none !important;
            }
        }

        &-prev {
            left: 25px;
            background: url($imgPath + "carousel-left-arrow.png") no-repeat 50%;

            @media (max-width: $screen-xs-max) {
                left: 20px;
            }
        }

        &-next {
            right: 25px;
            background: url($imgPath + "carousel-right-arrow.png") no-repeat 50%;

            @media (max-width: $screen-xs-max) {
                right: 20px;
            }
        }

        &-dots {
            top: 350px;
            pointer-events: all;

            @media (min-width: $screen-md-min) {
                bottom: 14px;
                top: auto;
            }

            @media (max-width: $screen-sm-max) {
                top: auto;
                bottom: 14px;
            }

            li button:before {
                width: 13px;
                height: 13px;
                content: "";
                opacity: .5;
                border: 2px solid white;
                border-radius: 50%;
            }

            .slick-active button:before {
                opacity: 1;
            }
        }
    }
}

// TEMP
html body .carousel img.hero-banner__bg {
    display: none !important;
}


.slick-dots {
    pointer-events: all;
}
