.c-tombstone-card {
    @include font-effra;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: t-spacing(6) t-spacing(4) 0 t-spacing(4);
    word-wrap: break-word;
    background-color: $neutral-white-100;
    min-width: 0;
	background-clip: border-box;
	margin-bottom: t-spacing(8);

	&.c-tombstone-card__info {
		display: flex;
		justify-content: center;
		height: 100%;
		min-height: 280px;
		border-bottom: 4px solid $primary-green-100;
	}
}

a.c-tombstone-card {
	&:hover {
		@include box-shadow($cta-box-shadow);
		background-color: $neutral-grey-10;
	}

	&:focus:not(:active) {
		outline: none;
		border-bottom-color: $focus-orange-100;
	}

	&:active {
		@include box-shadow($cta-box-shadow-active);
		background-color: $neutral-grey-20;
	}
}

.c-tombstone-card__header {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid $c-btn-primary-new;
	padding-bottom: t-spacing(4);
	margin-bottom: t-spacing(4); // next Component spacing
	min-height: 56px;
	// &.is-empty {
	// 	border-bottom: none;
	// }
	.c-tombstone-card__header-img {
		height: 40px;
		width: auto;
	}
	// Only show the border if there is an image
	img ~ .c-tombstone-card__header-price {
		border-left: 1px solid $neutral-grey-20;
	}

	.c-tombstone-card__header-price {
		font-weight: normal;
		color: $c-btn-primary-new;
		text-align: right;
		font-size: 22px;
		line-height: 1.27;
		display: flex;
		align-items: center;
		padding-left: t-spacing(4);
		margin-left: auto;
		min-height: 40px;
	}
}

.c-tombstone-card__headline {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    letter-spacing: normal;
    margin-bottom: t-spacing(4); // next Component spacing

    span {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.5;
        color: $c-black;
    }

    h4 {
        font-size: 22px;
        font-weight: normal;
        line-height: 1.27;
		color: $c-black !important; // Important used to override `_full-width.scss`
		margin-bottom: 0; // override from `_type.scss`
    }
}

.c-tombstone-card__info .c-tombstone-card__headline {
	margin-bottom: 0;

	.c-tombstone-filter__reset-btn {
		text-align: left;
	}
}

.c-tombstone-card__desc {
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $c-black;
    margin-bottom: t-spacing(8); // next Component spacing

    span {
        font-weight: normal;

    }

    p {
        font-weight: 300;
        margin-bottom: 0; // override from `_type.scss`
    }
}

.c-tombstone-card__logos {
    display: flex;
    margin-bottom: t-spacing(4); // next Component spacing

    img {
		height: 40px;
		width: auto;
        margin-left: t-spacing(2);
    }

    img:first-of-type {
        margin-left: 0;
    }
}

.c-tombstone-card__chips {
    display: flex;
	flex-flow: row wrap;
	align-content: start;
    border-top: 1px solid $neutral-grey-20;

    // All these rules are working in harmony with the badge margin top and bottom.
    padding: t-spacing(2) t-spacing(4) t-spacing(4) t-spacing(2);
    margin-left: -1 * t-spacing(4); // required to grow out of the card padding
	margin-right: -1 * t-spacing(4); // required to grow out of the card padding

	.badge {
		margin-top: t-spacing(2);
		margin-left: t-spacing(2);
		text-transform: uppercase;
	}
}

.badge {
	font-size: 10px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	display: inline-block;
	padding: t-spacing(2);
	// Not sure if this is the best idea to space badges vertically and horizontally.
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border: solid 1px $c-btn-primary-new;
	background-color: $neutral-white-100;
	color: $neutral-grey-100;
	// Empty badges collapse automatically
	&:empty {
		display: none;
	}

	&:hover {
		border-color: $c-btn-primary-new;
		color: $neutral-white-100;
		background-color: $c-btn-primary-new;
	}

	&:focus {
		color: $neutral-grey-100;
		border: solid 1px $focus-orange-100;
		background-color: $neutral-white-100;
	}

	&:active,
	&.is-active {
		border-color: $primary-blue-100;
		color: $neutral-white-100;
		background-color: $primary-blue-100;
	}

	&.is-inactive {
		border-color: $neutral-grey-10;
		background-color: $neutral-grey-10;
		color: $c-black;
	}
}

a.badge {
	text-decoration: none;
}

.rounded-pill {
	border-radius: 50rem !important;
}

.c-tombstone-card__cta {
	font-size: 16px;
	font-weight: normal;
	line-height: 1.5;
	color: $c-btn-primary-new;
	display: block;
	cursor: pointer;
	padding: t-spacing(4);
	margin-left: -1 * t-spacing(4); // required to grow out of the card padding
	margin-right: -1 * t-spacing(4); // required to grow out of the card padding
	background-color: $neutral-grey-10;
	border-bottom: 4px solid $c-btn-primary-new;
	user-select: none;
	text-decoration: none;
	outline: 0;

	&:hover {
		color: $c-btn-primary-new;
		background-color: $neutral-grey-20;
		border-bottom-color: $c-black;
	}

	&:focus {
		color: $c-btn-primary-new;
		background-color: $neutral-grey-10;
		border-bottom-color: $focus-orange-100;
	}

	&:active,
	&.is-active {
		color: $c-btn-primary-new;
		background-color: $neutral-grey-20;
		border-bottom-color: $c-black;
	}

	&.is-placeholder {
		background-color: $neutral-grey-10;
		border-bottom-color: $c-btn-primary-new;
		min-height: 60px;
		cursor: auto;
	}

	.bi {
		font-size: 24px;
		display: inline-block;
		// vertical-align: text-bottom;
		vertical-align: bottom;
	}
}
