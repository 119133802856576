.lightbox {
    cursor: pointer;
    cursor: -webkit-zoom-in;

    &-container {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.9);
        z-index: 10000;
        opacity: 0;
        transition: opacity 0.2s;
        cursor: pointer;
        cursor: -webkit-zoom-out;

        &:before {
            display: block;
            content: "X";
            top: 10px;
            right: 10px;
            position: absolute;
            color: rgba(255, 255, 255, 0.85);
            padding: 4px 13px;
            border-radius: 100%;
            border: 3px solid rgba(255, 255, 255, 0.85);
            background-color: rgba(2, 2, 2, 0.32);
            font-weight: bold;
            font-size: 22px;
            cursor: pointer;
            box-shadow: inset -2px 2px 12px -2px rgba(0, 0, 0, 0.22);
            z-index: 100;
        }

        .lightbox-alt-text {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1000;
            display: block;
            background-color: white;
            padding: 5px 10px;
            font-size: 13px;
            text-decoration: none;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }

        img {
            transform: translate(-50%, -50%);
            border: 0 solid #fff;
            width: auto !important;
            height: auto !important;
            transition: all 0.4s ease-in-out;
            cursor: default;
        }

        &.is-active {
            opacity: 1;

            .lightbox-alt-text {
                opacity: 1;
            }

            img {
                border-width: 15px;
                top: 50% !important;
                left: 50% !important;
                max-height: 90% !important;
                max-width: 90% !important;
            }
        }

        &.is-closing {
            opacity: 0;
        }
    }
}
