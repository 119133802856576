.p-transaction-detail__article-title {
	@include font-effra;
}

.p-transaction-detail__article-subtitle {
	@include font-effra;
	font-size: 28px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: $neutral-grey-100;
}

.p-transaction-detail__article-summary {
	@include font-effra;
	font-size: 22px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: $neutral-grey-100;
}

.p-transaction-detail__article-info {
	border-right: 1px solid $c-btn-primary-new;

	@media(max-width:$screen-sm-max) {
		border-right: none;
	}
}

.p-transaction-detail__article-title-wrapper {
	@media(max-width:$screen-sm-max) {
		border-bottom: 1px solid $primary-teal-100;
	}
}

.p-transaction-detail__article-price-wrapper {
	display: flex;
	flex-flow: row nowrap;

	.p-transaction-detail__article-price {
		margin-left: auto;
		padding-left: t-spacing(4);
		border-left: 3px solid $neutral-grey-20;
	}
}

.p-transaction-detail__article-img {
	max-width: 200px;
}

.p-transaction-detail__article-price {
	@include font-effra;
	font-size: 28px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: $c-btn-primary-new;
}

.p-transaction-detail__tags {
	padding: t-spacing(4) 0 t-spacing(2); // Padding bottom spacing-2 instead of 4 because the .badge inside alrady has a margin of 2.
	border-top: 1px solid $neutral-grey-20;
	border-bottom: 1px solid $neutral-grey-20;

	.badge {
		margin-bottom: t-spacing(2);
		margin-right: t-spacing(2);
		text-transform: uppercase;
	}
}

.p-transaction-detail__article-links {
	@include font-effra;

	.is-title {
		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: $neutral-grey-100;
	}

	.is-link {
		.btn { // This is needed to override the bootstrap btn styles.
			@include font-effra;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: $c-btn-primary-new;
			text-align: left;
			padding: 0;
		}

		.bi {
			font-size: 24px;
			display: inline-block;
			vertical-align: bottom;
		}
	}
}

.p-transaction-detail__team-header {
	@include font-effra;
	font-size: 28px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: $neutral-grey-100;
	margin-top: t-spacing(16) - 15px;
	margin-bottom: t-spacing(8);
}

.full-width h1.p-transaction-detail__header {
	@include font-effra;
	font-size: 28px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: $neutral-grey-100;
	margin-bottom: t-spacing(8);
}

.p-transaction-detail__support-container {
	@include font-effra;
	// background: url("/assets/Images/b_globallocation.jpg") no-repeat top center fixed;
	background-position-x: center;
	background-position-y: top;
	background-size: cover;
	background-repeat-x: no-repeat;
	background-repeat-y: no-repeat;
	background-attachment: fixed;
	-o-background-size: cover;
	background-size: cover;

	.p-transaction-detail__support-header {
		font-size: 28px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: $neutral-white-100;
	}
}
