// Characters which are escaped by the escape-svg function
$escaped-characters: (
	("<","%3c"),
	(">","%3e"),
	("#","%23"),
	("(","%28"),
	(")","%29"),
) !default;

// Debug Sass maps
@mixin debug-map($map) {
	@at-root {
		@debug-map {
			__tostring__: inspect($map);
			__length__: length($map);
			__depth__: depth($map);
			__keys__: map-keys($map);
			__properties__ {
				@each $key, $value in $map {
					#{'(' + type-of($value) + ') ' + $key}: inspect($value);
				}
			}
		}
	}
}

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// See https://codepen.io/kevinweber/pen/dXWoRw
@function escape-svg($string) {
	@if str-index($string, "data:image/svg+xml") {
		@each $char, $encoded in $escaped-characters {
			// Do not escape the url brackets
			@if str-index($string, "url(") == 1 {
				$string: url("#{str-replace(str-slice($string, 6, -3), $char, $encoded)}");
			} @else {
				$string: str-replace($string, $char, $encoded);
			}
		}
	}

	@return $string;
}
