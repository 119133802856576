﻿// Linear-gradient
// @param {Keyword | Angle} $direction - Linear gradient direction
// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}

@mixin background-lined-gradient($topColour) {
    $darker: darken($topColour, 1%);
    $darkest: darken($topColour, 4%);
    background: linear-gradient(149deg, $topColour 0%, $topColour 50%, $darker 50.2%, $darkest 100% );
}

@mixin clearfix() {
    & {
        *zoom: 1;
    }

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}


// ===============
//
//    HEADINGS
//
// ==============
@mixin heading($level) {
    font-weight: 100;
    margin-top: 0;
    margin-bottom: 25px;
    color: $cta-text-heading-dark;

    @if $level == 1 {
        font-size: 30px;
        line-height: 32px;
    }
    @else if $level == 2 {
        font-size: 24px;
        line-height: 28px;
    }
    @else if $level == 3 {
        font-size: 17px;
        line-height: 23px;
    }
    @else if $level == 4 {
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 15px;
    }
    @else if $level == 5 {
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 15px;
    }
}

// ===============
//
//    BUTTONS
//
// ==============

@mixin button($bgColor, $borderColor, $img) {
    background-color: $bgColor;
    color: white;
    box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.4);
    position: relative;
    padding-right: 55px;
    overflow: hidden;
    // Check to ensure we arent in the editor stylesheet which is broken by media queries
    @if $isEditMode == false {
        @media (max-width: $screen-sm-max) {
            width: 100%;
            min-width: auto;
        }
    }
    // Animation
    transition-duration: 0.3s;
    transition-property: color, background-color;
    // Default icon on right
    &:after {
        content: "";
        display: inline-block;
        width: 30px;
        height: calc(100% - 20px);
        position: absolute;
        top: 10px;
        right: 10px;
        float: right;
        background: $bgColor url($img) no-repeat 50% 50%;
        margin-left: 10px;
        padding-left: 30px;
        border-left: 1px solid $borderColor;
        // Animation
        transition-duration: 0.5s, 0.3s, 0.3s, 0.1s;
        transition-property: width, color, background-color, border-left;
        transition-timing-function: ease-in-out;
    }
    // Hover and Active states
    &:hover,
    &:focus,
    &:active,
    &:active:hover {
        position: relative;
        background-color: $cta-green-button;
        color: #fff;

        &:after {
            float: none;
            text-align: center;
            background-position: 50%;
            margin-left: 0;
            width: 100%;
            border-left-color: transparent;
            right: 0%;
            transition-duration: 0.5s, 0.3s, 0.3s, 0.1s;
            transition-property: width, color, background-color, border-left;
            transition-timing-function: ease-in-out;
            transition-delay: 0,0,0,0.3s;
            background-color: $cta-green-button;
        }
    }
    // On click
    &:active, &:active:hover {
        box-shadow: none;
        transition: box-shadow 0.2s;
    }
}


@mixin button-uk($bgColor, $imgPath) {
    color: white;
    background: $bgColor;
    position: relative;
    overflow: hidden;
    font-family: effra, sans-serif;
    z-index: 0;
    text-align: center;
    // Check to ensure we arent in the editor stylesheet which is broken by media queries
    @if $isEditMode == false {
        @media (max-width: $screen-sm-max) {
            width: 100%;
        }
    }
    // Default icon on right
    &:after {
        content: '';
        border-style: solid;
        border-color: $c-moonlight-teal transparent;
        border-width: 0px 0px 200px 90px;
        height: 0px;
        width: 0px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        background: transparent;
        background-color: transparent;
        padding: 0;
        // Animation
        transition-duration: 0.5s;
        transition-property: width;
        transition-timing-function: ease-in-out;
    }
    // Hover and Active states
    &:hover,
    &:focus,
    &:active,
    &:active:hover {
        position: relative;
        background: $bgColor;
        color: white;

        &:after {
            content: '';
            margin-left: 0;
            width: 160%;
            right: 0%;
            transition-duration: 0.5s;
            transition-property: width;
            transition-timing-function: ease-in-out;
            background: transparent;
            background-color: (white, $bgColor);
        }
    }
    // On click
    &:active, &:active:hover {
        box-shadow: none;
        transition: box-shadow 0.2s;
    }
}

//svg bg image
@mixin svg-icon-email($c-svg-fill) {
    background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"><circle class="st0" cx="50" cy="50" r="47" style="fill:none;stroke:#{$c-svg-fill};stroke-width:4;stroke-miterlimit:10;"/><g><polygon class="st1" points="41.4,48.8 26.8,36.5 26.8,65 	" fill="#{$c-svg-fill}"/><polygon class="st1" points="50,51.8 71,34.3 29.3,34.3 	" fill="#{$c-svg-fill}"/><polygon class="st1" points="58.8,48.8 73.2,64.8 73.2,36.7 	" fill="#{$c-svg-fill}"/><polygon class="st1" points="56.3,50.8 50,56 43.8,50.8 28.9,67.6 71.3,67.6 	" fill="#{$c-svg-fill}"/></g></svg>');
    background-repeat: no-repeat;
}



@mixin svg-icon-tel($c-svg-fill) {
    background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"><circle class="st0" cx="50" cy="50" r="47" style="fill:none;stroke:#{$c-svg-fill};stroke-width:4;stroke-miterlimit:10;"/><g><path class="st0" d="M70.2,62.3l-6.6-6.6c-1.3-1.3-3.4-1.3-4.8,0.1l-3.2,3.3c-0.2-0.1-0.5-0.3-0.6-0.4c-2-1.1-4.9-2.8-8-5.7c-3-3-4.6-5.8-5.7-8c-0.1-0.2-0.3-0.5-0.4-0.6l2.2-2.2l1.1-1.1c1.4-1.4,1.4-3.5,0.1-4.8l-6.6-6.6c-1.3-1.3-3.4-1.3-4.8,0.1L31,31.7l0.1,0.1c-0.6,0.8-1.1,1.7-1.5,2.7c-0.4,0.9-0.6,1.8-0.6,2.7c-0.8,7.1,2.4,13.7,11.3,22.6C52.6,72,62.4,71.1,62.9,71c0.9-0.1,1.9-0.4,2.7-0.6c1-0.4,1.9-0.9,2.7-1.5l0,0l1.8-1.8C71.5,65.7,71.5,63.6,70.2,62.3z" fill="#{$c-svg-fill}"/></g></svg>');
    background-repeat: no-repeat;
}


// IsVisbible
// This is used by passing in a variable that is either set to True or False, and depending on that variable will render display none. This is useful
// for disabling certain styling on certWain sites.
@mixin isVisible($toggle, $bool) {
    @if ($toggle == $bool) {
        @content;
    }
}


// Font Awesome
// Requires FontAwesome to be included in the project
// @param $icon-name - The matching suffix of the desired icon - required
// @param $font-size - The desired fontsize of the icon, default: 16px - optional
// @param $font-type - This indicates which font set to use, default: free - free/brand

@mixin fa-icon($icon-name, $font-size: 16px, $font-type: "free") {
    font-style: normal;
    font-size: $font-size;

    @if $font-type == 'free' {
        font-weight: 900;
        font-family: "Font Awesome 6 Free";
        font-style: normal;
    }
    @else if $font-type == 'brands' {
        font-family: "Font Awesome 6 Brands";
        font-style: normal;
    }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-variant: normal;
    line-height: 1;
    content: fa-content($icon-name);
}


//Fonts

//Effra - All instances
@mixin font-effra($font-weight: 400){
    font-family: effra, sans-serif;
    font-weight: $font-weight;
    font-style: normal;
}

//Essonnes - All instances
@mixin font-essonnes-display {
    font-family: essonnes-display, serif;
    font-weight: 400;
    font-style: normal;
}
