.c-cta-block {
	@include font-effra;
	padding: t-spacing(8) t-spacing(4);
	margin-bottom: t-spacing(6);

	.c-cta-block__title {
		font-size: 22px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.27;
		letter-spacing: normal;
		color: $neutral-grey-100;
	}

	.c-cta-block__text {
		font-size: 16px;
		font-weight: 300;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: $neutral-grey-100;

		p {
			color: $neutral-grey-100;
		}
	}

	.c-cta-block__cta {
		&:active {
			box-shadow: none;
		}

		&.btn { // This is needed to override the bootstrap btn styles.
			@include font-effra;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: $primary-blue-60;
			text-align: left;
			padding: 0;
		}

		.bi {
			font-size: 24px;
			display: inline-block;
			vertical-align: bottom;
		}
	}
}

a.c-cta-block {
	display: block;
	text-decoration: none;

	&.c-cta-block--light {
		&:hover {
			@include box-shadow($cta-box-shadow);
			background-color: $neutral-grey-10;
		}
	
		&:focus:not(:active) {
			border-bottom-color: $focus-orange-100;
		}
	
		&:active {
			@include box-shadow($cta-box-shadow-active);
			background-color: $neutral-grey-20;
		}
	}

	&.c-cta-block--dark {
		&:hover {
			@include box-shadow($cta-box-shadow);
			background-color: $primary-blue-80;
		}
	
		&:focus:not(:active) {
			border-bottom-color: $focus-orange-100;
		}
	
		&:active {
			@include box-shadow($cta-box-shadow-active);
			background-color: $primary-blue-100;
		}
	}

	&:hover,
	&:focus {
		.c-cta-block__cta {
			text-decoration: underline;
		}
	}
}

.c-cta-block--simple {
}

.c-cta-block--light {
	background-color: $neutral-white-100;
	border-bottom: 4px solid $primary-green-100;
}

.c-cta-block--dark {
	background-color: $primary-blue-60;
	border-bottom: 4px solid $primary-green-100;

	.c-cta-block__title {
		color: $neutral-white-100;
	}

	.c-cta-block__text {
		color: $neutral-white-100;

		p {
			color: $neutral-white-100;
		}
	}

	.c-cta-block__cta.btn {
		color: $neutral-white-100;
	}
}
