.btn {
    font-family: "ITC Franklink Gothic Medium", sans-serif;
    padding: 10px 35px;
    font-size: 14px;
    font-weight: bold;
    min-width: 175px;
    white-space: normal;

    & + .btn {
        @media (max-width: $screen-sm-max) {
            margin-top: 15px;
        }
    }

    @media (min-width: $screen-sm-min) {
        padding: 9px 9px;
    }

    &-primary, &-submit {
        @include button($c-btn-primary, $c-yellow, $imgPath + "btn-arrow.png");
    }

    &--lock {
        @include button($c-orange, $c-yellow, $imgPath + "btn-lock.png");
    }
    &--download {
        @include button($c-orange, white, $imgPath + "btn-download.png");
    }
    &-default, .form-cta-container &-submit {
        @include button($c-btn-default, white, $imgPath + "btn-arrow.png");
    }
}

a.btn {
    text-decoration: none;

    .html-block & {
        color: white;
    }
}

// Button on top of a button in mobile
a.btn + a.btn {
    @media (max-width: $screen-sm-max) {
        margin-top: 15px;
    }
}

// Button on top of a button in ul li on mobile
ul.button-list li {
    @media (max-width: $screen-sm-max) {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;

        a {
            width: auto;
        }
    }
}

.btn-action-gif {
	color: $c-moonlight-teal;
	/*background: $c-white;*/
	z-index: 1;
	text-align: center;
	font-size: 40px;
	position: absolute;
	top: 15px;
	right: 50px;
	border: transparent;
	background-color: transparent;

	.action {
		display: none;
	}

	@media (max-width: $screen-sm-max) {
		font-size: 30px;
		top: 2px;
		right: 5px;
	}
}
