.main-content .crumb, .crumb {
    padding-left: 0;
    margin-bottom: 35px;
    &__item {
        display: inline-block;
        font-size: 13px;

        &:last-child > a:after { display: none; }

        &--has-children {
            color: $c-breadcrumb-link-active;
            &:after {
                content: "v";
                color: $c-breadcrumb-link-active;
                display: inline-block;
                margin-left: 10px;
            }
        }
    }

    &__link {
        color: $c-breadcrumb-link;
        
        font-size: 13px;
        &:hover, &:focus {
            text-decoration: underline;
        }
        &:after {
            content: url($imgPath + "blue-right-arrow.png");
            display: inline-block;
            margin-left: 5px;
            color: $c-breadcrumb-link-active;
            font-weight: bold;
            font-size: 13px;
        }

        &--active {
            color: $c-breadcrumb-link-active;
            font-weight:normal;
        }
    }
}
