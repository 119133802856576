﻿#cookie-bar {
    background-color: $c-medgrey;
    line-height: 24px;
    color: white;
    padding: 18px 0;
    z-index: 100;
    position: relative;
    left: 0;
    right: 0;
    top: 0;

    .cookie-bar-container { margin: 0 auto; position: relative; }

    p {
        margin: 0;
        padding: 0;
        text-align: left;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        color: white;
    }

    a {
        color: white;
        text-decoration: underline;
    }

    .cb-policy {
        text-decoration: underline;
    }

    .cookie-message {
        width: 80%;
        float: left;
    }

    .close-btn {
        font-size: 0;
        text-decoration: none;
        &:before {
            content: "×";
            color: white;
            font: 60px/100% arial,sans-serif;
            text-decoration: none;
            position: absolute;
            right: 15px;
            top: -8px;
        }
    }
}
