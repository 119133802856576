.full-width {
    padding: 52px 0 40px;
    color: $text-color;

    h1,h2,h3,h4,h5 { color: $c-lightblue; }
    h2 { margin-bottom: 35px; }

    &--lightgrey, &--light {
        background-color: $c-lightgrey;
        color: $text-color;
    }

    &--medgrey {
        background-color: $c-medgrey;
        color: white;
        h1,h2,h3,h4,h5 { color: white; }
    }

    &--disclaimer {
        background-color: $c-disclaimer-bg;
        color: white;
        padding: 40px 0 30px;
    }

    &--green {
        background-color: $c-green;
        color: white;
        h1,h2,h3,h4,h5 { color: white; }
    }

    &--blue {
        background-color: $c-lightblue;
        color: white;
        h1,h2,h3,h4,h5 { color: white; }
    }

    &--lightblue {
        background-color: $c-lightblue;
        color: white;
        h1,h2,h3,h4,h5 { color: white; }
    }

    &--darkblue {
        background-color: $c-darkblue;
        color: white;
        h1,h2,h3,h4,h5 { color: white; }
    }

	&--darkgrey {
		background-color: $c-black;
		color: white;
		h1,h2,h3,h4,h5 { color: white; }
	}

    &--bg {
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        color: white;

        @media (min-width: $screen-sm-min) {
            padding: 100px 0 90px;
        }

        h1,h2,h3,h4,h5 { color: white; }

        &.dark {
            &,
            .hero-text,
            .hero-overlay,
            .hero-text__wrapper .hero-text,
            h1,h2,h3,h4,h5 {
                color: $c-darkgrey;
            }
        }
    }
}

.col-breakout {
    padding-left: 0;
    padding-right: 0;
    /*float: left;*/
    position: relative;
    min-height: 1px;
    margin-left: -15px;
    margin-right: -15px;

    @media (min-width: $screen-sm-min) {
        width: 100vw;
        left: 50%;
        margin-left: -50vw;

        .col-sm-8 &{
            margin-left: -50vw;
            left: 21.6%;
        }
    }

    @media (min-width: $screen-md-min) {
        .col-md-9 & {
            margin-left: -50vw;
            left: 31.43333%;
        }
    }
}
