nav {
    display: inline-block;

    @media (max-width: $screen-md-max) {
        width: 100%;
    }
}

.primary-nav {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    width: 67%;
    /*width: 64%;*/
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        width: 79%;
    }

    @media (max-width: $screen-xs-max) {
        width: 300px;
        position: absolute;
        top: 0;
        left: -301px;
        bottom: 0;
        z-index: 1000;
        transition: -webkit-transform 0.6s;
        transition: transform 0.6s;
        transition: transform 0.6s, -webkit-transform 0.6s;
        background: rgba(0, 0, 0, 0.6);

        &.is-active {
            -webkit-transform: translateX(301px);
            -ms-transform: translateX(301px);
            transform: translateX(301px);
        }

        .dropdown {
            background: $c-darkblue;

            & > .primary-nav__panel {
                width: 300px;
                position: absolute;
                top: 0;
                left: -300px;
                bottom: 0;
                z-index: 1000;
                transition: -webkit-transform 0.6s;
                transition: transform 0.6s;
                transition: transform 0.6s, -webkit-transform 0.6s;
                background: $c-darkblue;
                margin: 0;
            }

            &.is-active > .primary-nav__panel {
                -webkit-transform: translateX(300px);
                -ms-transform: translateX(300px);
                transform: translateX(300px);
                display: block;
            }
        }
    }

    &_back.primary-nav__link {
        background: url(/assets/images/back-arrow.png) no-repeat 0% 50%;
        padding-left: 30px;
        cursor: pointer;
    }

    &__list {
        @media (max-width: $screen-xs-max) {
            .btn--lock {
                display: inline-block !important;
            }
        }

        @media (min-width: $screen-sm-min) {
            height: auto !important;
            text-align: center;
        }

        @media (min-width: $screen-md-min) {
            /*text-align: right;*/
        }

        margin-bottom: 0;
    }

    &__item {
        display: inline-block;
        margin-right: 25px;

        @media (max-width: $screen-xs-max) {
            width: 100%;
            margin-right: 0;
            background-color: $c-darkblue;
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
            margin-right: 12px;
        }

        &.dropdown {
            position: initial;

            @media (min-width: $screen-sm-min) {
                position: relative;
            }

            > a {
                @media (min-width: $screen-sm-min) {
                    &:after {
                        content: '';
                        background: url($imgPath + 'nav-dropdown.png') no-repeat
                            0 0;
                        display: inline-block;
                        margin-left: 7px;
                        color: white;
                        width: 12px;
                        height: 7px;
                        position: relative;
                        bottom: 1px;
                    }
                }
            }
            @media (min-width: $screen-sm-min) {
                &:hover .primary-nav__panel {
                    display: block;
                    opacity: 1;
                    .primary-nav__panel {
                        display: none;
                    }
                }
            }
        }
    }

    &__label {
        cursor: pointer;
    }

    &__link {
        color: white;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        padding: 20px 0;
        display: block;
        position: relative;

        @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
            font-size: 13px;
        }

        @media (max-width: $screen-xs-max) {
            margin: 0 20px;
            border-bottom: 2px solid rgba(255, 255, 255, 0.4);
            cursor: pointer;
            font-size: 18px;
            font-weight: 500;

            &--additional {
                padding-top: 15px;
                padding-bottom: 15px;
                font-size: 18px;
            }

            &.has-children {
                background: url(/assets/images/mobile-nav-arrow-right.png)
                    no-repeat 100% 50%;
                padding-right: 10px;
            }
        }
        @media (min-width: $screen-sm-min) {
            &:focus,
            &:hover {
                text-decoration: underline;
                color: $c-cg-careers-primary-nav;
            }
        }

        @media (min-width: $screen-sm-min) {
            &--active:before,
            &:hover:before,
            &--active .primary-nav__link-span:before,
            &:hover .primary-nav__link-span:before {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                content: '';
                display: block;
                bottom: 15px;

                .primary-nav__panel & {
                    bottom: -3px;
                }
            }
        }
    }

    &__lower {
        padding-left: 0;
    }

    &__panel {
        display: none;
        color: white;
        position: absolute;
        top: 58px;
        left: 0;
        right: 0;
        margin: 0;
        min-width: 260px;
        max-width: 300px;
        margin-left: 50%;
        z-index: 20;

        @media (min-width: $screen-sm-min) {
            left: auto;
            right: auto;
            text-align: center;
        }

        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);

        .primary-nav__item {
            margin-right: 0;
            display: block;
            padding: 10px 0;
            list-style: none;
            background-color: $c-darkblue;

            @media (min-width: $screen-sm-min) {
                margin: 25px 0 10px;
                border-radius: 6px;

                &:after {
                    top: 5px;
                    left: 50%;
                    border: solid transparent;
                    content: ' ';
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(214, 117, 61, 0);
                    border-bottom-color: $c-darkblue;
                    border-width: 10px;
                    margin-left: -10px;
                }
            }

            .primary-nav__item:after {
                display: none;
            }
        }

        li.primary-nav__item {
            @media (min-width: $screen-sm-min) {
                padding: 0;
            }
        }

        ul.primary-nav__item {
            @media (min-width: $screen-sm-min) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .primary-nav__link {
            line-height: 1.2;
            margin-bottom: 10px;
            font-weight: 500;
            font-style: normal;

            @media (min-width: $screen-sm-min) {
                font-size: 14px;
                text-align: left;
                padding: 2px 5px;
                display: block;
                position: relative;
                font-weight: 300;

                &--active,
                &:hover {
                    &:before {
                        display: none;
                    }
                }

                &:focus {
                    @media (max-width: $screen-xs-max) {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .primary-nav__item .primary-nav__item.dropdown > a:after {
        display: none;
    }
}

.primary-nav__panel .primary-nav__item {
    .primary-nav__item {
        margin: 0;
        padding: 0;
    }

    ul.primary-nav__item {
        @media (max-width: $screen-xs-max) {
            padding-top: 10px;
        }
    }
}
