.accordion {
    &-block {
        margin-bottom: 40px;
    }

    &__toggle {
        background-color: $c-lightblue;
        color: white;
        font-size: 18px;
        padding: 27px 25px;
        border-radius: 3px;
        position: relative;
        font-family: "Centennial Roman", serif;

        @media (min-width: $screen-sm-min) {
            padding: 27px 45px;
        }

        &:after {
            content: "";
            background: url($imgPath + "accordion-arrow-up.png") no-repeat 0 0;
            position: absolute;
            top: 27px;
            right: 25px;
            width: 9px;
            height: 21px;

            @media (min-width: $screen-sm-min) {
                right: 62px;
            }
        }

        &.collapsed:after {
            background: url($imgPath + "accordion-arrow-down.png") no-repeat 0 0;
        }
    }

    &__body {
        background-color: $c-paleblue;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;

        .panel-body {
            padding: 22px 25px;

            @media (min-width: $screen-sm-min) {
                padding: 22px 45px;
            }
        }


        .contact__link {
            color: $text-color;
            background-image: url(/assets/images/tel-icon.png);
        }
    }

    &__panel {
        margin-bottom: 15px;

        & img.img-responsive.pull-right {
            margin-bottom: 30px;
        }
    }
}
