.tertiary {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    &__item {
        display: inline-block;
    }

    &__link {
        color: white;
        font-size: 16px;

        @media(min-width:$screen-xs-max) and (max-width:$screen-sm-max) {
            font-size: 14px;
        }

        &:focus { text-decoration: none; }
    }

    footer & {
        @media (max-width: $screen-xs-max) {
            margin-top: 25px;
            float: none !important;
        }
    }
}
