.p-transactions__intro-m {
	@include font-effra;
	font-size: 22px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: $neutral-grey-100;
}

.p-transactions__intro-s {
	@include font-effra;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: $neutral-grey-100;
}

.p-transactions__footer-container {
	background-color: $neutral-grey-100;
	color: white;
	padding: $padding-base-vertical * 3 0;

	* {
		color: white;
	}

	.container > *:first-child {
		margin-top: 0;
	}

	.container > *:last-child {
		margin-bottom: 0;
	}

	.container ol,
	.container ul {
		padding-left: $padding-large-horizontal;
	}
}