.search {
    &__list {
        list-style: none;
        padding-left: 0;
    }

    &__item {
        margin-bottom: 20px;
    }

    &__title {
        font-size: 18px;
        display: block;
    }

    &__summary {
        margin-bottom: 0;
        p {
            margin-bottom: 0;
        }
    }

    &__url {
        font-size: 14px;
        margin-bottom: 5px;
        display: block;
    }
}

.pagination {
    padding-left: 0;
    margin: 20px 0;
    li {
        display: inline-block;
    }
    a {
        color: $c-medgrey;
        font-size: 18px;
        padding: 6px 6px;
        display: inline-block;

        &.next,
        &.active {
            color: $c-lightblue;
        }
    }
}
