﻿/*.video-banner {
    position: absolute;
    top: -12vw;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    padding-bottom: 56.25vw;
    > iframe {
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__container {
        position: relative;
    }
}*/


.video-banner {
    position: relative;
    width: 100%;
    display: block;
    padding: 0;
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        padding-top: 56.25%;
    }

    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;

        // To remove black bars on video 
        top: -10vw;
    }

    &__container {
        // To remove black bars on video 
        height: 35.5vw;
        overflow: hidden;
    }
}