.quote, blockquote {
    position: relative;
    padding: 20px 30px;
    text-align: left;
    margin-bottom: 25px;

    .container > & {
        margin: 0 100px;

        @media (min-width: $screen-sm-min) {
            text-align: center;
            padding: 20px 110px 0;
        }
    }

    &:before, &:after {
        content: url($imgPath + "quote-open.png");
        color: white;
        display: inline-block;
        position: relative;
        width: 60px;
        height: 45px;

        @media (min-width: $screen-sm-min) {
            .container > & {
                position: absolute;
            }
        }
    }

    &:before {
        content: url($imgPath + "quote-open.png");
        top: 0;
        left: 0;
        margin-bottom: 20px;

        @media (min-width: $screen-sm-min) {
            .container > & {
                margin-bottom: 0;
            }
        }
    }

    &:after {
        content: url($imgPath + "quote-close.png");
        bottom: 0;
        right: 0;
        margin-top: 0px;
        float: right;
        top: -33px;

        @media (min-width: $screen-sm-min) {
            .container > & {
                margin-top: 0;
            }
        }
    }

    &--green {
        background-color: $c-green;
        color: white;
    }

    &--blue {
        background-color: $c-lightblue;
        color: white;
    }

    &__text, .main-content &__text p {
        font-size: 22px;
        display: inline-block;
        color: white;
        max-width: 860px;
    }

    &__author {
        text-align: center;
        font-size: 16px;
        color: white;
        display: block;
    }
}
