ul {
    &.tick {
        padding-left: 0;

        li {
            list-style: none;
            display: inline-block;
            background: url($imgPath + "list-tick.png") no-repeat 0 0%;
            padding-left: 60px;
            min-height: 43px;
            width: 100%;
            color: $c-ir-main-text;
            margin-bottom: 20px;
            background-size: 40px;
            vertical-align: top;

            .full-width--bg & {
                color: white;
                background: url($imgPath + "list-tick-white.png") no-repeat 0 0%;
            }

            @media (min-width: $screen-sm-min) {
                background-size: initial;
                width: 32.5%;
                padding-top: 5px;
                padding-right: 40px;
                min-height: 50px;
                margin-bottom: 28px;
            }
        } 

        @at-root #{&}--four {
            @extend .tick;
            li {
                @media (min-width: $screen-sm-min) {
                    width: 24%;
                    padding-right: 20px;
                }
            }
        }
    }

    &.tag {
        padding-left: 20px;

        @media (min-width: $screen-sm-min) {
            padding-left: 0;
        }

        li {
            list-style: none;
            display: inline-block;
            background: url($imgPath + "list-tag.png") no-repeat 0 0%;
            padding-left: 47px;
            padding-right: 20px;
            padding-top: 5px;
            min-height: 35px;
            margin-bottom: 11px;
            color: $c-medgrey;
            width: 100%;
            vertical-align: top;
            background-size: 35px;


            @media (min-width: $screen-sm-min) {
                background-size: initial;
                width: 32.5%;
                padding-top: 9px;
                padding-right: 40px;
                padding-left: 58px;
                min-height: 50px;
                margin-bottom: 28px;
            }

            a {
                box-shadow: 0 2px 0 -1px #80a1b6;
                padding-bottom: 2px;
                display: inline-block;

                &:after {
                    content: "";
                    width: 21px;
                    height: 9px;
                    background: url($imgPath + "pagination-next.png") no-repeat 0 50%;
                    display: inline-block;
                    margin-left: 5px;
                    padding-left: 22px;
                    transition: margin-left 0.15s ease-in-out;
                }

                &:hover {
                    text-decoration: none;

                    &:after {
                        margin-left: 10px;
                        transition: margin-left 0.15s ease-in-out;
                    }
                }

                &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    &.document {
        padding-left: 0;
        margin-bottom: 25px;

        li {
            list-style: none;
            display: block;
            background: url($imgPath + "list-document--small.png") no-repeat 2px 0%;
            padding-left: 30px;
            min-height: 22px;
            margin-bottom: 8px;
            vertical-align: top;

            > a {
                padding-left: 40px;
                margin-left: -40px;
            }
        }
    }

    &.split-list {
        &__two {
            column-count: 1;
            column-gap: $grid-gutter-width + 5;
            column-rule: none;

            @media (min-width: $screen-sm-min) {
                column-count: 2;
            }
        }

        &__three {
            column-count: 1;
            column-gap: $grid-gutter-width + 5;
            column-rule: none;

            @media (min-width: $screen-sm-min) {
                column-count: 3;
            }
        }
    }
}

.contact {
    list-style: none;
    padding-left: 0;
    margin: 20px 0 0px;

    @media (min-width: $screen-sm-min) {
        margin: 20px 0 30px;
    }



    li {
        display: inline-block;
        width: 100%;
        margin-bottom: 15px;

        @media (min-width: $screen-sm-min) {
            width: 24.5%;
            margin-bottom: 0;
        }
    }

    &__link {
        color: white;
        font-size: 16px;
        background: url($imgPath + 'tel-icon-white.png') no-repeat 0 50%;
        padding-left: 40px;
        display: block;

        .cta--green & a {
            color: white;
            box-shadow: none;
        }
    }
}


.NewsArticle__list--CTA {
    h2 { margin-bottom: 25px; }
    li {
        position: relative;
        padding-bottom: 30px;
        padding-top: 15px;
        list-style: none;
        background: url(/assets/images/list-document.png) no-repeat 2px 14px;
        background-size: 25px;
        padding-left: 35px;

        & a {
            padding-left: 40px;
            margin-left: -40px;
        }
    }
}
