@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=73c2aefb-0f82-4b02-b024-075f00d7f3f8");

// Book
@font-face {
	font-family: "ITC Franklin Gothic";
	src: url($fontPath + "00faf685-e292-40f6-8318-fce19d0576b1.eot?#iefix");
	src: url($fontPath + "00faf685-e292-40f6-8318-fce19d0576b1.eot?#iefix") format("eot"),url($fontPath + "35d6841f-a247-4989-95f3-b023bab7c876.woff2") format("woff2"),url($fontPath + "e0c48fbf-6e3e-4d4a-8443-7905cf3d47f4.woff") format("woff"),url($fontPath + "52a8cfe0-c0a3-4424-80ae-baf160636666.ttf") format("truetype"),url($fontPath + "df864b94-3d8a-4e16-9ec8-ed159a688ca5.svg#df864b94-3d8a-4e16-9ec8-ed159a688ca5") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
	unicode-range: U+0000-00AD, U+00AF-FFFF; // Registration mark symbol override
}


//Medium
@font-face {
	font-family: "ITC Franklin Gothic";
	src: url($fontPath + "38e58634-5c71-4832-9385-35ec6bec173b.eot?#iefix");
	src: url($fontPath + "38e58634-5c71-4832-9385-35ec6bec173b.eot?#iefix") format("eot"),url($fontPath + "2c1be1db-5a84-48fe-9f13-b0683b78abef.woff2") format("woff2"),url($fontPath + "e5b902cc-1869-4595-8d52-876961f81b63.woff") format("woff"),url($fontPath + "f53691b6-96af-440d-bfd5-84ebc1c6d61d.ttf") format("truetype"),url($fontPath + "26d3e21c-9b55-4c1a-bbcf-e9c350c89184.svg#26d3e21c-9b55-4c1a-bbcf-e9c350c89184") format("svg");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
	unicode-range: U+0000-00AD, U+00AF-FFFF; // Registration mark symbol override
}

// Book Italic
@font-face {
	font-family: "ITC Franklin Gothic";
	src: url($fontPath + "c463593c-0d1b-4eb3-81d5-4726fede670e.eot?#iefix");
	src: url($fontPath + "c463593c-0d1b-4eb3-81d5-4726fede670e.eot?#iefix") format("eot"),url($fontPath + "b989ffc9-8d10-4590-b302-c4e061ae4c70.woff2") format("woff2"),url($fontPath + "fc275cd6-d94e-4b34-81a3-5e285aef463f.woff") format("woff"),url($fontPath + "4be04772-5db4-49da-8797-714e732e6142.ttf") format("truetype"),url($fontPath + "01f820f1-3aa6-45c4-9847-95511a2eeb7a.svg#01f820f1-3aa6-45c4-9847-95511a2eeb7a") format("svg");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
	unicode-range: U+0000-00AD, U+00AF-FFFF; // Registration mark symbol override
}

// Demi
@font-face {
	font-family: "ITC Franklin Gothic";
	src: url($fontPath + "f7a7a643-32ec-435d-bc04-5f749f687a7b.eot?#iefix");
	src: url($fontPath + "f7a7a643-32ec-435d-bc04-5f749f687a7b.eot?#iefix") format("eot"),url($fontPath + "cb2236cd-07ed-4fc7-bbb9-6668f83544e4.woff2") format("woff2"),url($fontPath + "805949e8-2090-4f08-b0fe-c670ad7a44fc.woff") format("woff"),url($fontPath + "13304278-6ad2-4539-9fed-32fa737800b4.ttf") format("truetype"),url($fontPath + "814ed673-f448-4aeb-81f1-4bb7b2bc3175.svg#814ed673-f448-4aeb-81f1-4bb7b2bc3175") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
	unicode-range: U+0000-00AD, U+00AF-FFFF; // Registration mark symbol override
}

// Demi italic
@font-face {
	font-family: "ITC Franklin Gothic";
	src: url($fontPath + "2729cf9a-259a-494e-9f71-ef6ef366e029.eot?#iefix");
	src: url($fontPath + "2729cf9a-259a-494e-9f71-ef6ef366e029.eot?#iefix") format("eot"),url($fontPath + "dbff1efd-9e9c-47a0-af64-1e86a317adb9.woff2") format("woff2"),url($fontPath + "ab0b1fd5-18ca-44dc-93a8-93f643c91a4e.woff") format("woff"),url($fontPath + "b0669e21-83ee-4bc2-89dd-7a700c99cdc1.ttf") format("truetype"),url($fontPath + "c117a74b-7cc2-4dae-81e6-6fb75ba9b94a.svg#c117a74b-7cc2-4dae-81e6-6fb75ba9b94a") format("svg");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
	unicode-range: U+0000-00AD, U+00AF-FFFF; // Registration mark symbol override
}

// Centennial
@font-face {
	font-family: "Centennial Roman";
	src: url($fontPath + "bdc648af-918f-4e59-9326-a0f6d25d7f5a.eot?#iefix");
	src: url($fontPath + "bdc648af-918f-4e59-9326-a0f6d25d7f5a.eot?#iefix") format("eot"),url($fontPath + "163b869c-000f-4c09-9bdb-1c303cc3bbdd.woff2") format("woff2"),url($fontPath + "c4990d97-8a7d-4f5c-95ec-d67137347c88.woff") format("woff"),url($fontPath + "049081c8-eea2-422e-9767-9656bb75f352.ttf") format("truetype"),url($fontPath + "8bd269ac-abe9-4cf9-a77d-5e432f79a131.svg#8bd269ac-abe9-4cf9-a77d-5e432f79a131") format("svg");
	font-display: swap;
}

/* source-serif-4-regular - cyrillic_cyrillic-ext_greek_latin_latin-ext_vietnamese */
/* generated from https://gwfh.mranftl.com/fonts/source-serif-4?subsets=cyrillic,cyrillic-ext,greek,latin,latin-ext,vietnamese*/
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Source Serif Pro'; 
	font-style: normal;
	font-weight: 400;
	src: url($fontPathAdamAndCompany + "source-serif-4-regular.woff2") format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
		 url($fontPathAdamAndCompany + "source-serif-4-regular.ttf") format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
  } 