﻿.profile {
    &-details {
        .blog__author-details__wrapper {
            margin-top: 0;
        }
        @media (min-width: $screen-sm-min) {
            padding: $profile-padding;
        }
        .blog__author-contact-details {
            padding-top: 0;
            margin-bottom: 15px;
        }

        &__pic {
            margin: 0 auto 15px;
            max-width: 150px;
            max-height: 150px;
            @media (min-width: $screen-lg) {
                margin: 0;
                float: right;
                margin-left: 15px;
                margin-bottom: 15px;
                
            }
        }
    }
}