.pullOutStatistic {
	border-left: 1px solid $c-statistic;
	padding-left: 20px;
	margin-bottom: 42px;
	line-height: 1;

	p {
		margin-bottom: 0px;
		color: $c-statistic;
	}

	&__header {
		top: -4px;
		margin-bottom: 7px;
	}

	&__header,
	&__number,
	&__footer {
		color: $c-statistic;
		font-size: 18px;
		margin-bottom: 0px;
		position: relative;

		p {
			color: $c-cm-lightblue; // the cms is injecting a p tag
		}
	}

	&__header {
		top: -4px;
	}

	&__footer {
		bottom: -3px; //forces bottom alignment with side border
	}

	&__number {
		font-size: 42px;
		margin-bottom: 5px;
	}

	@media (min-width: $screen-sm-min) and (max-width: $screen-md) {
		&__number {
			font-size: 34px;
		}
	}
}
