﻿@media print {
    .header__logo img {
        display: inline-block !important;
    }

    .header-top__info,
    .language-selector,
    .primary-nav,
    .canaccord-external-links__wrapper,
    .hero-banner,
    .footer-top,
    .footer-bottom .pull-right,
    .full-width--disclaimer,
    .btn,
    .btn-primary,
    .carousel,
    .read-more,
    .read-more-toggle,
    .button-list,
    .pagination,
    .mobile-nav__toggle {
        display: none !important;
    }

    .container.pull-up {
        margin-top: 0;
    }

    body,
    .intro {
        font-size: 14px !important;
    }

    .full-width {
        padding: 52px 0 0px !important;
    }

    .full-width--bg {
        padding: 10px 0 !important;
    }

    h1,
    h2.h1 {
        font-size: 30px !important;
    }

    ul.tag li {
        display: inline-block !important;
        background: url(/assets/images/list-tag.png) no-repeat 0 0 !important;
    }

    a[href]:after {
        display: none !important;
    }
}
