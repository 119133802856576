//
// Base -> Typography
//
// Use this file for site-wide typographic
// adjustments, including web fonts and headings.
// --------------------------------------------------


.h2, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {

    font-weight: 100;
    margin-top: 0;
    margin-bottom: 25px;
    color: $c-heading;
}

h1, .h1 {
    @include heading(1);


    @media (min-width: $screen-md-min) {
        font-size: 42px;
        line-height: 48px;
    }
}

h2, .h2 {
    @include heading(2);

    @media (min-width: $screen-md-min) {
        font-size: 28px;
        line-height: 34px;
    }
}

h3, .h3 {
    @include heading(3);

    @media (min-width: $screen-md-min) {
        font-size: 22px;
        line-height: 27px;
    }
}

h4, .h4 {
    @include heading(4);

    @media (min-width: $screen-md-min) {
        font-size: 18px;
        line-height: 23px;
    }
}

h5, .h5 {
    @include heading(5);

    @media (min-width: $screen-md-min) {
        font-size: 16px;
        line-height: 20px;
    }
}

p {
    margin-bottom: 15px;
}

select, input {
    -webkit-border-radius: 0;
}

ul {
    .html-block & {
        margin-bottom: 25px;
    }
}

a, .html-block a {
    color: $c-lightslate;
    font-weight: bold;
    /*.html-block &:not(.btn):not(.document-downloads__link):not(.contact__link a) {
        color: $c-styledlink;
        box-shadow: 0 2px 0px -1px $c-lightblue;
        text-decoration: none;
    }*/
    &.tel-link,
    &.email-link,
    &.fax-link,
    &.web-link {
        &:before {
            border: 1px solid $c-darkblue;
            content: "";
            display: inline-block;
            border-radius: 50%;
            margin-right: 10px;
            position: relative;
            top: 8px;
            width: 30px;
            height: 30px;
        }

        display: inline-block;
        min-height: 30px;
        padding-top: 4px;
        color: $c-styledlink;
        font-size: 16px;
        box-shadow: none;
    }

    &.tel-link {
        &:before {
            background: url(/assets/images/tel-icon-borderless.png) no-repeat 50% 50%;
        }

        .accordion__body & {
            color: $c-tabs-secondary;
        }
    }

    &.email-link {
        &:before {
            background: url(/assets/images/email-icon-borderless.png) no-repeat -2px 35%;
        }

        .accordion__body & {
            color: $c-tabs-secondary;
        }
    }

    &.fax-link {
        &:before {
            background: url(/assets/images/fax-icon-borderless.png) no-repeat -2px 35%;
        }

        .accordion__body & {
            color: $c-tabs-secondary;
        }
    }

    &.web-link {
        &:before {
            top: 10px;
            background: url(/assets/images/web-icon-borderless.png) no-repeat -1px 35%;
        }

        .accordion__body & {
            color: $c-tabs-secondary;
        }
    }
}

.fax-link {
    @extend a.fax-link;
}

.tel-link {
    @extend a.tel-link;
}

.web-link {
    @extend a.web-link;
}

.email-link {
    @extend a.email-link;
}

.intro {
    font-size: 18px;
    line-height: 23px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 25px;
}

.text-center-xs {
    @media (max-width: $screen-sm-max) {
        text-align: center;
    }
}

.read-more {
    color: $c-styledlink;
    box-shadow: 0 2px 0px -1px $c-styledlink;
    padding-bottom: 2px;

    &:after {
        content: "";
        width: 21px;
        height: 9px;
        background: url($imgPath + "pagination-next.png") no-repeat 0 50%;
        display: inline-block;
        margin-left: 5px;
        padding-left: 22px;
        transition: margin-left 0.15s ease-in-out;
    }

    &:hover, &:focus, &:active {
        text-decoration: none;

        &:after {
            margin-left: 10px;
            transition: margin-left 0.15s ease-in-out;
        }
    }
}

p.narrow {
    text-align: center;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
}

.muted-link {
    color: $link-hover-color;

    &:hover, &:focus {
        text-decoration: underline;
    }

    @include isVisible($show-read-more, false) {
        &:after {
            content: "\203a";
            color: inherit;
            display: inline-block;
            font-family: Source Sans Pro,Helvetica Neue,Arial,sans-serif;
            font-style: normal;
            font-weight: 200;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: relative;
            font-size: 1em;
            top: 0;
            padding-left: 10px;
            margin-right: -15px;
        }
    }
}

.read-more-toggle {
    @include isVisible($show-read-more, false) {
        display: none !important;
    }
}

.no-underline {
    border-bottom: none !important;
}
