$global-baseline: 4px;

$spacing-1: 1 * $global-baseline;
$spacing-2: 2 * $global-baseline;
$spacing-3: 3 * $global-baseline;
$spacing-4: 4 * $global-baseline;
$spacing-5: 5 * $global-baseline;
$spacing-6: 6 * $global-baseline;
$spacing-7: 7 * $global-baseline;
$spacing-8: 8 * $global-baseline;
$spacing-9: 9 * $global-baseline;
$spacing-10: 10 * $global-baseline;
$spacing-11: 11 * $global-baseline;
$spacing-12: 12 * $global-baseline;
$spacing-13: 13 * $global-baseline;
$spacing-14: 14 * $global-baseline;
$spacing-15: 15 * $global-baseline;
$spacing-16: 16 * $global-baseline;
$spacing-17: 17 * $global-baseline;
$spacing-18: 18 * $global-baseline;
$spacing-19: 19 * $global-baseline;
$spacing-20: 20 * $global-baseline;

$global-spacing-sizes: (
	0: 0,
	1: $spacing-1,
	2: $spacing-2,
	3: $spacing-3,
	4: $spacing-4,
	5: $spacing-5,
	6: $spacing-6,
	7: $spacing-7,
	8: $spacing-8,
	9: $spacing-9,
	10: $spacing-10,
	11: $spacing-11,
	12: $spacing-12,
	13: $spacing-13,
	14: $spacing-14,
	15: $spacing-15,
	16: $spacing-16,
	17: $spacing-17,
	18: $spacing-18,
	19: $spacing-19,
	20: $spacing-20
) !default;

@function t-spacing($size) {
	$spacing: map-get($global-spacing-sizes, $size);

	@if not map-has-key($global-spacing-sizes, $size) {
		@error "Invalid style specified! #{$size} doesn't exist. Choose one of #{map-keys($global-spacing-sizes)}";
	}

	@return $spacing;
}

$spacing-directions: (
	null: null,
	"t": "-top",
	"r": "-right",
	"b": "-bottom",
	"l": "-left"
);

$spacing-properties: (
	"p": "padding",
	"m": "margin"
);

// stylelint-disable max-nesting-depth
@each $property-namespace, $property in $spacing-properties {
	@each $direction-namespace, $direction-rule in $spacing-directions {
		@each $size-namespace, $size in $global-spacing-sizes {
			.u-#{$property-namespace}#{$direction-namespace}-#{$size-namespace} {
				#{$property}#{$direction-rule}: $size !important;
			}
		}
	}
}

//* When changing how this works, keep in mind specificity.
// If we iterate over all tokens first we can end up with more spcific tokens for sm than for md.
// We need to iterate first by breakpoint to group them togheter.
@each $breakpoint in map-keys($global-breakpoints) {
	@each $property-namespace, $property in $spacing-properties {
		@each $direction-namespace, $direction-rule in $spacing-directions {
			@each $size-namespace, $size in $global-spacing-sizes {
				.#{$breakpoint}\:u-#{$property-namespace}#{$direction-namespace}-#{$size-namespace} {
					@media (min-width: map-get($global-breakpoints, $breakpoint)) {
						#{$property}#{$direction-rule}: $size !important;
					}
				}
			}
		}
	}
}
