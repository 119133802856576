.ceros-experience-form {
    visibility: hidden;
    height: 0;
}

.ceros-experience-container {
    overflow: hidden;
}

.ceros-experience-container__fullscreen {
    margin-bottom: 91px;
    @media (min-width: $screen-md-min) {
        margin-bottom: 68px;
    }
}
