.pagination, .main-content .pagination {
    padding-left: 0;
    list-style: none;
    margin: 0 auto 35px;
    width: 100%;
    text-align: center;
    display: block;

    &__item {
        display: inline-block;
        vertical-align: middle;
    }

    &__link {
        color: $c-pagination-link;
        text-decoration: none;
        padding: 10px;
        display: inline-block;

        &:focus, &:hover {
            color: $c-pagination-link-active;
            text-decoration: underline;
        }

        &.pagination__link--prev {
            content: "";
            background: url($imgPath + "pagination-prev.png") no-repeat 0 50%;
        }

        &.pagination__link--next {
            content: "";
            background: url($imgPath + "pagination-next.png") no-repeat 0 50%;
        }

        &--active {
            color: $c-pagination-link-active;
        }
    }
}
