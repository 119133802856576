/**
 * Hide visually and from screen readers.
 */
.u-hidden {
	display: none !important;
}

@each $breakpoint in map-keys($global-breakpoints) {
	.#{$breakpoint}\:u-hidden {
		@media (min-width: map-get($global-breakpoints, $breakpoint)) {
			display: none !important;
		}
	}
}

/**
 * Apply display box type of an element.
 */
 .u-block {
	display: block !important;
}

@each $breakpoint in map-keys($global-breakpoints) {
	@if ($breakpoint != "xs") {
		.#{$breakpoint}\:u-block {
			@media (min-width: map-get($global-breakpoints, $breakpoint)) {
				display: block !important;
			}
		}
	}
}
