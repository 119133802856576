.full-width .blog,
.blog {
    &__detail {
        &-img {
            margin-bottom: 15px;

            > img {
                display: block;
                max-width: 100%;
                height: auto;
            }
        }

        .blog__info {
            margin-bottom: 15px;
        }
    }

    &__list {
    }

    &__body {
        margin-bottom: 40px;
    }

    &__cta {
        margin-bottom: 35px;
        color: #676c73;
        background-color: #fff;

        @include isVisible($show-cta-shadows, true) {
            box-shadow: 0 5px 10px 4px rgba(0,0,0,.12);
        }

        &--large {
            background-position: 50% !important;
            background-size: cover !important;
            position: relative;

            .blog__cta-img-alt {
                display: none;
            }

            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                .blog__cta {
                    &-img {
                        display: none;
                    }

                    &-img-alt {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 50%;
                        bottom: 0;
                        background-repeat: no-repeat;
                        background-size: cover;
                        z-index: 30;
                        display: block;
                        background-position: 50%;
                    }

                    &-body {
                        float: right;
                        display: inline-block;
                        z-index: 50;
                        position: relative;
                    }
                }
            }

            .blog__cta-body {
                @media (min-width: $screen-sm-min) {
                    padding-left: 40px;
                    padding-right: 40px;
                }
            }
        }

        &-img {
            max-width: 100%;
            width: 100%;

            &-bg {
                width: 100%;
                height: 0;
                padding-bottom: 52.77%;
                display: block;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }

        &-title {
            color: #3e3e3e;
            font-size: 18px;
            margin-bottom: 3px;
            display: block;
            font-weight: bold;
        }

        &-body {
            color: $c-medgrey;
            padding: 25px 19px;


            .read-more {
                color: $c-lightblue;

                @include isVisible($show-read-more, false) {
                    display: none !important;
                }
            }
        }
    }

    &__info {
        font-size: 13px;
        color: $c-medgrey;
        margin-bottom: 5px;
        display: block;

        a {
            text-decoration: none;
            color: $c-darkblue;
            font-weight: bold;
        }
    }

    &__author, .blog__author.profile-details {
        background-color: $c-blog-bg;
        color: $c-darkgrey;
        padding: 25px 39px 39px;
        padding: $profile-padding;
        margin-bottom: 35px;

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            padding: 35px 20px 35px;
            padding: $profile-padding;

            .blog__author-contact-details .btn {
                max-width: 300px;
            }
        }

        @media (min-width: $screen-md-min) {
            padding: 25px 39px 15px;
            padding: $profile-padding;
        }

        h1, h2, h3, h4, h5 {
            color: $c-blog-light-grey;
            font-weight: bold;
        }

        &-img {
            @media (max-width: $screen-xs-max) {
                margin: 0 auto 15px;
            }
        }

        .main-content & {
            &-email, &-tel, &-linkedin, &-twitter {
                display: inline-block;
                text-decoration: none;
                min-height: 40px;
                margin-bottom: 4px;
                font-weight: bold;

                &:hover, &:focus {
                    text-decoration: underline;
                    font-weight: bold
                }

                &:visited {
                    color: $c-cm-profile-link;
                }
            }

            &-linkedin, &-twitter {
                margin-top: 15px;
                min-height: 0;
            }
        }

        &-email {
            /*background: url($imgPath + "email-icon-borderless.png") no-repeat left 5px; 
            padding-left: 43px;*/
            color: inherit;
            word-break: break-all;
        }

        &-tel {
            /*background: url($imgPath + "tel-icon-borderless.png") no-repeat 0 0;
            padding-left: 43px;*/
            color: inherit;
        }

        &-linkedin {
            background: url($imgPath + "linkedin.png") no-repeat 0 50%;
            padding-left: 43px;
            margin-top: 15px;
            min-height: 0;
        }

        &-twitter {
            background: url($imgPath + "twitter.png") no-repeat 0 50%;
            padding-left: 43px;
            margin-top: 15px;
            min-height: 0;
        }

        &-details {
            padding-left: 0;
            list-style: none;

            &__heading {
                font-size: 19px;
                margin-bottom: 5px;
            }

            &__item {
                line-height: 2;
            }

            &__wrapper {
                /*margin-top: 10px;
                @media (min-width: $screen-sm-min) {
                    margin-top: -3px;
                }

                @media (min-width: $screen-md-min) {
                    margin-top: -9px;
                }*/
            }
        }

        &-contact-details {
            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                padding-top: 10px;
            }

            @media (min-width: $screen-md-min) {
                padding-top: 28px;
            }

            a.btn {
                min-width: 240px;

                @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                    min-width: initial;
                }
            }
        }
    }

    &__summary {
        margin-bottom: 15px;
    }
}
