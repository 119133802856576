.video-block {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    display: block;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,0.4) url($imgPath + 'video-play.png') no-repeat 50%;
        z-index: 1000;
        transition: background-color 0.2s;
    }
    &:hover:before {
        transition: background-color 0.2s;
        background-color: rgba(0,0,0,0);
    }
}
