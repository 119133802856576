@import 'base/variables';
/*$header-background-color: yellow !default;*/ //if no other variable property exists then use this by default

@import 'common';
@import 'base/buttons';
$c-dark-grey: #62656a;

.start-page {
	@include font-effra;
	color: $c-dark-grey;

	.site-cta-container > div:first-child {
		display: flex;
		flex-wrap: wrap;
		align-content: space-between;
		justify-content: center;
		margin-right: auto;
    	margin-left: auto;
    	padding-left: 15px;
    	padding-right: 15px;
		width: 75%;
		@media screen and (max-width: 600px){
			width: 100%;
		}

		> div {
			@media screen and (min-width: 1321px){
				width: 33.3333%;
			}
			@media screen and (min-width: 992px) and (max-width: 1321px){
				width: 50%;
			}
			@media screen and (max-width: 992px){
				width: 100%;
			}
			padding-left: 15px;
			padding-right: 15px;
			.site-cta-body {
				margin-bottom: 30px;
			}
		}
	}

	.container {
		width: 90%;

		:after, :before {
			content: none;
		}
	}

	h1, h2, h3 {
		@include font-effra;
		color: #000;
		margin-bottom: 15px;
	}

	.header-top {
		background-color: #fff;
		min-height: 0;
		padding: 15px 0 18px;

		@media (min-width: $screen-sm-min) {
			padding: 32px 0 35px;
		}
	}

	.hero-text__wrapper .hero-text {
		@include font-essonnes-display;
		text-align: center;
		font-size: 8px;

		@media (min-width: 500px) {
			font-size: 8px;
		}

		@media (min-width: $screen-sm-min) {
			font-size: 13px;
		}

		@media (min-width: $screen-md-min) {
			font-size: 14px;
		}

		@media (min-width: $screen-lg) {
			font-size: 17px;
		}

		.head {
			font-size: 2.9em;
			display: inline-block;
			line-height: 1.35;

			@media (min-width: 600px) {
				max-width: 54%;
			}

			@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
				line-height: 1.2;
			}
		}

		.tail {
			font-size: 2em;
			display: inline-block;
			line-height: 1.35;
			@include font-effra;
			font-weight: 400;

			@media (min-width: 600px) {
				max-width: 56%;
			}

			@media (min-width: $screen-sm-min) {
				font-size: 1.23em;
			}
		}
	}

	.hero-banner__overlay {
		@media(max-width:$screen-sm-max) {
			padding: 0 20px 40px;
		}

		h2, h3 {
			line-height: 1.2;
			@include font-effra;
			color: #fff;

			@media(max-width:$screen-sm-max) {
				max-width: 100% !important;
			}
		}

		h2 {

			@media(max-width:$screen-sm-max) {
				font-size: 1.5em !important;
			}
		}

		h3 {

			@media(max-width:$screen-sm-max) {
				font-size: 1em !important;
			}
		}

		.head {
			font-size: 3em;
			display: inline-block;
			max-width: 54%;
		}

		.tail {
			font-size: 1.3em;
			display: inline-block;
			max-width: 56%;
		}

		.btn-primary {
			background-color: #00856f;
			margin-top: 1em;

			&:after {
				background: #00856f;
				background: #00856f url(/assets/images/btn-arrow.png) no-repeat 50% 50%;
				border-left: 1px solid #fff;
			}
		}
	}

	.strapline-container {
		color: #000;
		padding-top: 6px;
		font-size: 20px;
		font-weight: 600;
	}

	.btn {
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		margin-right: 15px;

		&:nth-child(2) {
			margin-right: 0;
		}

		@media(mac-width:$screen-xs-max) {
			margin-right: 0;
		}
	}

	.wm-intro-wrapper, .cm-intro-wrapper, .ir-intro-wrapper, .fm-intro-wrapper, .site-cta {
		border: 1px solid $c-border-grey;
		border-radius: 3px;
		padding: 25px 39px;
		margin-top: 30px;
	}

	.wm-intro-wrapper {
		width: 100%;


		h2 {
			color: #006385;
		}

		.btn.disabled {
			cursor: not-allowed;
		}

		.select-wrapper {
			display: inline-block;
			position: relative;
			width: auto;
			padding-bottom: 15px;

			select {
				-moz-appearance: window;
				-webkit-appearance: none;
				border-radius: 3px;
				padding: 10px;
				width: 100%;
				min-width: 220px;
				font-weight: 500;
				color: #006385;
				@media screen and (max-width: 600px) {
					min-width: 150px;
				}
			}

			&:after {
				content: '';
				position: absolute;
				background: white url(/assets/images/start-page/country-select-icon.png) 50%;
				background-size: 20px;
				background-repeat: no-repeat;
				right: 9px;
				top: 7px;
				height: 30px;
				width: 20px;
				padding-right: 32px;
				pointer-events: none;
			}

			@media(max-width:$screen-sm-max) {
				width: 100%;
			}
		}

		.btn.btn-primary {
			color: #006385;
			background: transparent;
			border: 1px solid #006385;

			&:after {
				background: #fff url(/assets/images/start-page/btn-arrow.png) no-repeat 50% 50%;
				border-left: 1px solid #006385;
			}

			&:hover:after {
				border-left: 1px solid transparent;
			}
		}

		.btn.btn-default {
			color: #fff;
			background: #006385;
			border: 1px solid #006385;
		}

		.btn.btn-default:after {
			background-color: #006385;
		}
	}

	.fm-intro-wrapper {

		h2 {
			color: #0067A0;
		}

		.btn.btn-primary {
			color: #0067A0;
			background: transparent;
			border: 1px solid #0067A0;

			&:after {
				background: #fff url(/assets/images/start-page/fm-btn-arrow.png) no-repeat 50% 50%;
				border-left: 1px solid #0067A0;
			}

			&:hover:after {
				border-left: 1px solid transparent;
			}
		}

		.btn.btn-default {
			color: #fff;
			background: #0067A0;
			border: 1px solid #0067A0;
		}

		.btn.btn-default:after {
			background-color: #0067A0;
		}
	}

	.pin-intro-wrapper {

		h2 {
			color: #000;
		}

		.btn.btn-primary {
			color: #000;
			background: transparent;
			border: 1px solid #000;

			&:after {
				background: #fff url(/assets/images/start-page/fm-btn-arrow.png) no-repeat 50% 50%;
				border-left: 1px solid #000;
			}

			&:hover:after {
				border-left: 1px solid transparent;
			}
		}

		.btn.btn-default {
			color: #fff;
			background: #000;
			border: 1px solid #000;
		}

		.btn.btn-default:after {
			background-color: #000;
		}
	}

	.cm-intro-wrapper {

		h2 {
			color: #008099;
		}

		.btn.btn-primary {
			color: #008099;
			background: transparent;
			border: 1px solid #008099;

			&:after {
				background: #fff url(/assets/images/start-page/gcm-btn-arrow.png) no-repeat 50% 50%;
				border-left: 1px solid #008099;
			}

			&:hover:after {
				border-left: 1px solid transparent;
			}
		}

		.btn.btn-default {
			color: #fff;
			background: #008099;
			border: 1px solid #008099;
		}

		.btn.btn-default:after {
			background-color: #008099;
		}
	}

	.ir-intro-wrapper {

		h2 {
			color: #53565b;
		}

		margin-bottom: 30px;
		display: inline-block;
		width: 100%;

		.btn-alignment {
			@media (min-width: $screen-md-min) {
				padding-left: 40px;
				padding-top: 30px;
			}
		}

		.left, .right {
			display: inline-block;
			float: left;
		}

		.left {
			width: 50%;

			@media(max-width:$screen-sm-max) {
				width: 100%;
			}
		}

		.right {
			width: 40%;

			@media(max-width:$screen-md-max) {
				width: 50%;
			}

			@media(max-width:$screen-sm-max) {
				width: 100%;
			}

			& p.pull-right {
				@media(max-width:$screen-sm-max) {
					float: none !important;
				}
			}
		}

		.btn.btn-primary {
			color: #53565b;
			background: transparent;
			border: 1px solid #53565b;

			&:after {
				background: #fff url(/assets/images/start-page/wm-btn-arrow.png) no-repeat 50% 50%;
				border-left: 1px solid #53565b;
			}

			&:hover:after {
				border-left: 1px solid transparent;
			}
		}

		.btn.btn-default {
			color: #fff;
			background: #53565b;
		}

		.btn.btn-default:after {
			background-color: #53565b;
		}
	}


	.footer-top {
		background-color: white;
		color: #000;
		padding: 11px 0 15px;

		.container {
			padding-top: 10px;
		}
	}

	.full-width.full-width--disclaimer {
		background-color: $c-disclaimer-bg;
		color: #2e2b27;
		padding: 25px 0;
		text-align: left;
		margin-top: 30px;

		.footer-logo > p {
			margin-bottom: 0;
		}
	}

	.hero-banner.dark {
		.hero-text,
		.hero-overlay,
		.hero-banner__overlay h1,
		.hero-banner__overlay h2,
		.hero-banner__overlay h3 {
			color: $c-darkgrey;
		}
	}
}

	@import 'print';
