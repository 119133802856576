.back-to-top {
    cursor: pointer;
    position: fixed;
    width: 42px;
    height: 42px;
    display:none;
    background: url(/assets/images/back-to-top.png) 50% 50%;
    z-index: 10000;
    bottom: 60px;
    right: 50px;
    @media (min-width: $screen-sm-min) {
        bottom: 140px;
        right: 140px;
    }
    @media (max-width: 767px) {
		bottom: 100px;
		right: 10px;
	}
} 
